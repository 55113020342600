import React, {Fragment, memo} from 'react'
import {BannerSection, FlightSection, Offer} from "components";


const HomePage = memo((props) =>{
    return(
        <Fragment>
            <FlightSection {...props}/>
            <Offer/>
            <BannerSection/>
        </Fragment>
    )
})

export default HomePage;
