import React, {Fragment, useEffect, useState} from 'react'

import apis from "services";
import {getParams, constants} from "utils";
import {useMutation, useQuery} from "react-query";
import {FlightsLoader} from "components";
import {useDispatch} from "react-redux";
import {resetFlights} from "../redux/actions/flight";

const FlightBookingSuccessPage = () => {
    const dispatch = useDispatch()

    const [state, setState] = useState({Order_Ref_Number: '', Payment_Type: '', message: '', status: false});
    const [pnr, setPNR] = useState('');
    const [errorMsgs, setErrorMsgs] = useState([]);

    const {isLoading: bookingStatusLoader} = useQuery([getParams().data], apis.successBooking, {
        onError: (err) => console.log(`Error Occurred while Booking Success ${err}`),
        onSuccess: ({data}) => {
            if (data.status) {
                console.log('data-RQ', data)
                setState(data);
                // fetchBooking({id: data.Order_Ref_Number})
                //confirmBooking({id: data.Order_Ref_Number})
            }
        }
    });

    const {mutate: confirmBooking, isLoading: bookingLoader} = useMutation('', apis.confirmBooking, {
        onError: (error) => {
            console.log(error.response.data.Response.message);
            console.log(`Error occurred while create booking -${error}`)
        },
        onSuccess: ({data}) => {
            console.log('data', data)
            setPNR(data?.PNR)
            console.log(data)
        }
    });

    useEffect(() => {
        dispatch(resetFlights())
    }, [])


    const {
        mutate: fetchBooking,
        isLoading: fetchingPNRLoader
    } = useMutation('bookingDetails', apis.fetchBookingDetails, {
        onError: (error) => console.log(`Error Occurred while fetching booking details - ${error}`),
        onSuccess: ({data}) => {
            console.log('data PNR', data)
            setPNR(data)
        }
    });

    // console.log({fetchingPNRLoader, bookingStatusLoader})

    const handleDownloadInvoice = () => {
        //alert('Available soon...')
    }

    return (
        <Fragment>
            <section className="bg-inner section-b-space success-section">
                <div className="container">
                    <FlightsLoader/>

                    {(bookingStatusLoader === false && fetchingPNRLoader === false && bookingLoader === false) ? (
                        <div className="row success-detail">
                            <div className="col">
                                {console.log('state', state)}
                                <h2>Booking Successful ! get ready to fly</h2>
                                <p> {state.message} </p>
                                {/*<p> thank you for using chaloje.</p>
                                <p>you will get an email invoice soon!</p>*/}
                                {/*<button onClick={handleDownloadInvoice} className="btn btn-solid color1">download invoice</button>*/}
                                <a className="btn btn-solid color1" target="_blank" href={`${constants.BACKEND}flight/pdf/${state.Order_Ref_Number}`}>Download invoice</a>
                            </div>
                        </div>
                    ) : <h2 className='text-center'>Processing, Please wait</h2>
                    }
                </div>
            </section>
        </Fragment>
    )
}

export default FlightBookingSuccessPage
