import React, {Fragment} from "react";
import {useSelector} from "react-redux";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import InputMask from 'react-input-mask';


const TravelerDetailForms = ({onChange, handleContactOnChange, travelersData, contactData, Airline}) => {
    // useScript("./assets/js/jquery.maskedinput.min.js");

    const {travelers} = useSelector((store) => store.flight);

    return (
        <div className="col-md-12">
            {Object.entries(travelers).map(([traveler, quantity]) =>
                Array.from({length: Number(quantity)}).map((passenger, index) => {
                    const passengerDob = travelersData[traveler][index]?.['Dob'] || '';
                    const passengerCnic = travelersData[traveler][index]?.['Cnic'] || '';
                    const passengerTitle = travelersData[traveler][index]?.['Title'] || '';
                    const passengerLastname = travelersData[traveler][index]?.['Lastname'] || '';
                    const passengerFirstname = travelersData[traveler][index]?.['Firstname'] || '';
                    const passengerWheelChair = travelersData[traveler][index]?.['WheelChair'] || '';


                    return (
                        <Fragment key={index}>
                            <div className="form">
                                <h6>
                                    {traveler} {index + 1}
                                </h6>
                                <div className="row">
                                    <div className="form-group col-md-2">
                                        <label htmlFor={`title ${index + 1}`}>Title</label>
                                        <select
                                            id={`title ${index + 1}`}
                                            className="form-control"
                                            name="title"
                                            value={passengerTitle}
                                            onChange={(event) =>
                                                onChange(event, traveler, `Title`, index)
                                            }
                                            required
                                        >
                                            <option value="" selected>
                                                Choose...
                                            </option>
                                            {(() => {
                                                switch (Airline) {
                                                    case 'Airsial':
                                                        if (['INFANT', 'CHILD'].includes(traveler)) {
                                                            return <Fragment>
                                                                <option>MR</option>
                                                                <option>MISS</option>
                                                            </Fragment>
                                                        } else
                                                            return <Fragment>
                                                                <option>MR</option>
                                                                <option>MS</option>
                                                                <option>MRS</option>
                                                                >
                                                            </Fragment>
                                                    case 'Airblue':
                                                        if (traveler === 'CHILD') {
                                                            return (<Fragment>
                                                                <option>Mstr</option>
                                                                <option>Miss</option>
                                                            </Fragment>)
                                                        }
                                                        if (traveler === 'INFANT') {
                                                            return (<Fragment>
                                                                <option>Male</option>
                                                                <option>Female</option>
                                                            </Fragment>)
                                                        }
                                                        if (traveler === 'ADULT') {
                                                            return (<Fragment>
                                                                <option>MR</option>
                                                                <option>MS</option>
                                                                <option>MRS</option>
                                                            </Fragment>)
                                                        }
                                                        break;
                                                    default:
                                                        break;
                                                }
                                            })()}
                                        </select>
                                    </div>
                                    <div className="form-group col-md-5">
                                        <label htmlFor="first_name">First Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="first_name"
                                            name="Firstname"
                                            value={passengerFirstname}
                                            required
                                            onChange={(event) =>
                                                onChange(event, traveler, `Firstname`, index)
                                            }
                                        />
                                    </div>
                                    <div className="form-group col-md-5">
                                        <label htmlFor="last_name">Last Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="last_name"
                                            name="Lastname"
                                            value={passengerLastname}
                                            required
                                            onChange={(event) =>
                                                onChange(event, traveler, `Lastname`, index)
                                            }
                                        />
                                    </div>
                                    <div className="form-group col-md-2">
                                        <label htmlFor="inputState">Wheelchair</label>
                                        <select
                                            id="WheelChair"
                                            className="form-control"
                                            value={passengerWheelChair}
                                            onChange={(event) =>
                                                onChange(event, traveler, `WheelChair`, index)
                                            }
                                        >
                                            <option selected value="">
                                                Choose...
                                            </option>
                                            <option>No</option>
                                            <option>Yes</option>
                                        </select>
                                    </div>
                                    {(() => {
                                        if (traveler !== 'INFANT') {
                                            return <div className="form-group col-md-5">
                                                <label htmlFor="Cnic">CNIC</label>
                                                <InputMask
                                                    className="form-control"
                                                    mask="99999-9999999-9"
                                                    id="Cnic"
                                                    name="Cnic"
                                                    onChange={(event) =>
                                                        onChange(event, traveler, `Cnic`, index)
                                                    }
                                                    value={passengerCnic}
                                                    required
                                                />
                                            </div>
                                        }
                                    })()}
                                    <div className="form-group col-md-5">
                                        <label htmlFor="Dob">Date of Birth</label>
                                        <input
                                            type="date"
                                            className="form-control"
                                            id="Dob"
                                            value={passengerDob}
                                            onChange={(event) =>
                                                onChange(event, traveler, `Dob`, index)
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                    )
                })
            )}
            <h6>Contact Details</h6>
            <div className="row">
                <div className="form-group col-md-6">
                    <label htmlFor="email">Email</label>
                    <input
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        value={contactData.email}
                        onChange={handleContactOnChange}
                    />
                </div>
                <div className="form-group col-md-6">
                    <label htmlFor="mobile_no">Phone no:</label>
                    <PhoneInput
                        id="mobile_no"
                        country={'pk'}
                        value={contactData.PrimaryCell}
                        onChange={(phone) => handleContactOnChange({name: 'mobile', value: phone})}
                    />
                </div>
                <div className="form-group col-md-6">
                    <label htmlFor="cnic">CNIC</label>
                    <InputMask
                        class='form-control'
                        mask="99999-9999999-9"
                        name='cnic'
                        onChange={handleContactOnChange}
                        value={contactData.cnic}
                        required
                    />
                </div>
                <div className="form-group col-md-6">
                    <label htmlFor="comments">Commnets</label>
                    <textarea className='form-control' placeholder='Comments' rows={1}
                              onChange={(e) => handleContactOnChange({name: 'comments', value: e.target.value})}>
                    </textarea>
                </div>
                <div className="form-group col-12">
                    <div className="form-check collection-filter-checkbox">
                        {/*<input className="form-check-input" type="checkbox" value="" id="defaultCheck1"/>*/}
                        <input type="checkbox" className="form-check-input" id="register" name='register'
                               onChange={(e) => handleContactOnChange({name: 'register', value: e.target.checked})}/>
                        <label className="form-check-label ms-2" htmlFor="register">
                            Register with this information
                        </label>
                    </div>
                </div>
                {contactData.register && (<Fragment>
                    <div className="form-group col-md-6">
                        <label htmlFor="password">Password</label>
                        <input
                            type="password"
                            className="form-control"
                            id="password"
                            name="password"
                            value={contactData.password}
                            onChange={handleContactOnChange}
                            required
                        />
                    </div>

                    <div className="form-group col-md-6">
                        <label htmlFor="confirmPassword">Confirm Password</label>
                        <input
                            type="password"
                            className="form-control"
                            id="confirmPassword"
                            name="confirmPassword"
                            value={contactData.confirmPassword}
                            onChange={handleContactOnChange}
                            required
                        />
                    </div>
                </Fragment>)}
            </div>
        </div>
    );
};

export default TravelerDetailForms;
