import React, {memo} from 'react'
import {AutoComplete} from "./index";

const FlightSearch = memo(({
                               handleSubmit,
                               handleChange,
                               flightData,
                               getTravellersValue,
                               handleTravelerPlusButton,
                               handleTravelerMinusButton,
                               cities
                           }) => {
    return (
        <div className="flight-search">
            <div className="flight-search-detail">
                <form className="row m-0" onSubmit={handleSubmit}>
                    <div className="lg-12">
                        <div className="form-group gap-2">
                            <input className='form-check-input radio_animated'
                                   id="radio-1" type="radio" name="flightType" value="round"
                                   onChange={handleChange}
                                   defaultChecked={flightData.flightType === 'round'}/>
                            <label htmlFor="radio-1" className="form-check-label title me-2">round
                                trip</label>
                            <input id="radio-2" type="radio" name="flightType" value='one-way'
                                   onChange={handleChange}
                                   defaultChecked={flightData.flightType === 'one-way'}/>
                            <label htmlFor="radio-2" className="radio-label">one way</label>
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <AutoComplete name='locationDep' onChange={handleChange} data={cities}
                                      placeholder='From' label='from' required
                                      defaultValue={flightData.locationDep}/>
                    </div>
                    <div className="col-lg-2">
                        <AutoComplete name='locationArr' onChange={handleChange} placeholder='To'
                                      data={cities} to label='To' required
                                      defaultValue={flightData.locationArr}/>
                    </div>
                    <div className="col-lg-2">
                        <div className="form-group">
                            <label>departure date</label>
                            <input placeholder="Depart Date" id="datepicker" autoComplete='off'
                                   defaultValue={flightData.departingOn} required/>
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div className="form-group">
                            <label>return date</label>
                            <input placeholder="Return Date" id="datepicker1" autoComplete='off'
                                   disabled={flightData.flightType === 'one-way'}
                                   defaultValue={flightData.returnOn}
                                   required={flightData.flightType === 'round'}
                            />
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div className="form-group">
                            <label>traveller & class</label>
                            <input type="text" className="form-control open-select"
                                   placeholder="traveller"
                                   value={getTravellersValue(flightData.travelers)}/>
                            <img src="./assets/images/icon/user.png"
                                 className="img-fluid blur-up lazyload" alt=""/>
                            <div className="selector-box-flight">
                                <div className="room-cls">
                                    <div className="qty-box">
                                        <label>adult</label>
                                        <div className="input-group">
                                            <button type="button"
                                                    className="btn quantity-left-minus"
                                                    onClick={() => handleChange('traveler', 'adultNo', -1)}
                                                    style={handleTravelerMinusButton('adult')}
                                            > -
                                            </button>
                                            <input type="text" name="adult"
                                                   className="form-control qty-input input-number"
                                                   value={flightData.travelers.adultNo}
                                            />
                                            <button type="button"
                                                    className="btn quantity-right-plus"
                                                    onClick={() => handleChange('traveler', 'adultNo', 1)}
                                                    style={handleTravelerPlusButton('adult')}
                                            >+
                                            </button>
                                        </div>
                                    </div>
                                    <div className="qty-box">
                                        <label>Child</label>
                                        <div className="input-group">
                                            <button type="button"
                                                    className="btn quantity-left-minus"
                                                    onClick={() => handleChange('traveler', 'childNo', -1)}
                                                    style={handleTravelerMinusButton('child')}
                                            > -
                                            </button>
                                            <input type="text" name="quantity"
                                                   className="form-control qty-input input-number"
                                                   value={flightData.travelers.childNo}/>
                                            <button type="button"
                                                    className="btn quantity-right-plus"
                                                    onClick={() => handleChange('traveler', 'childNo', 1)}
                                                    style={handleTravelerPlusButton('child')}
                                            > +
                                            </button>
                                        </div>
                                    </div>
                                    <div className="qty-box">
                                        <label>infants</label>
                                        <div className="input-group">
                                            <button type="button"
                                                    className="btn quantity-left-minus"
                                                    onClick={() => handleChange('traveler', 'infantNo', -1)}
                                                    style={handleTravelerMinusButton('infant')}
                                            > -
                                            </button>
                                            <input type="text" name="quantity"
                                                   className="form-control qty-input input-number"
                                                   value={flightData.travelers.infantNo}/>
                                            <button type="button"
                                                    className="btn quantity-right-plus"
                                                    onClick={() => handleChange('traveler', 'infantNo', 1)}
                                                    style={handleTravelerPlusButton('infant')}
                                            > +
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="flight-class">
                                    <div className="form-check">
                                        <input
                                            className="form-check-input radio_animated"
                                            type="radio"
                                            id="economy"
                                            name="class"
                                            value="economy"
                                            onChange={handleChange}
                                            checked={flightData.class === 'economy'}/>
                                        <label className="form-check-label"
                                               htmlFor="economy">
                                            economy
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input radio_animated"
                                            type="radio"
                                            onChange={handleChange}
                                            name="class"
                                            value="premium"
                                            checked={flightData.class === 'premium'}
                                        />
                                        <label className="form-check-label"
                                               htmlFor="premium">
                                            premium
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input radio_animated"
                                            type="radio"
                                            onChange={handleChange}
                                            name="class" id="business"
                                            value="business"

                                            checked={flightData.class === 'business'}
                                        />
                                        <label className="form-check-label"
                                               htmlFor="business">
                                            business
                                        </label>
                                    </div>
                                </div>
                                <div className="bottom-part">
                                    <button
                                        type='button'
                                        className="btn">apply
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div className="search-btn">
                            <button type='submit' className="btn btn-solid color1">search</button>
                        </div>
                    </div>
                    <div className="responsive-close">
                        <i className="far fa-times-circle"/>
                    </div>
                </form>
            </div>
        </div>
    )
})

export default FlightSearch
