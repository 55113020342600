import React, {Fragment} from 'react'
import {Accordion} from "./index";
import {useSelector} from "react-redux";
import {keysIn, keysInLength} from "utils/objects";

const PassengerDetails = ({passengerData}) => {

    const {travelers} = useSelector((store) => store.flight);

    return (
        <Fragment>
            {keysInLength(travelers) > 0 && keysIn(travelers)
                .map((passenger) =>
                    passengerData[passenger].length > 0 && passengerData[passenger]
                        .map((_passenger, index) => {
                            const name = `${_passenger?.Firstname} ${_passenger?.Lastname}`;
                            const fullName = `${_passenger?.Title}. ${name}`;

                            return (
                                <Accordion title={fullName} expanded icon='user' key={index}>
                                    <div className="row">
                                        <div className="col">
                                            <dl>
                                                <dt>Traveler Name</dt>
                                                <dd> {name} </dd>
                                            </dl>
                                        </div>
                                        <div className="col">
                                            <dl>
                                                <dt>Type</dt>
                                                <dd>{passenger}</dd>
                                            </dl>
                                        </div>
                                        <div className="col">
                                            <dl>
                                                <dt>Wheelchair</dt>
                                                <dd>{_passenger.WheelChair}</dd>
                                            </dl>
                                        </div>
                                        <div className="col">
                                            <dl>
                                                <dt>Date of Birth</dt>
                                                <dd>{_passenger.Dob}</dd>
                                            </dl>
                                        </div>
                                        {passenger !== 'INFANT' && (
                                            <div className="col">
                                                <dl>
                                                    <dt>Nic</dt>
                                                    <dd>{_passenger.Cnic}</dd>
                                                </dl>
                                            </div>
                                        )}
                                    </div>
                                </Accordion>
                            )
                        })
                )}
        </Fragment>
    )
};

export default PassengerDetails;