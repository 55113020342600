import React, {useEffect, useState} from "react";

const useFlight = () => {
    const [flightData, setFlightData] = useState({
        locationDep: "",
        locationArr: "",
        departingOn: "",
        returnOn: "",
        travelers: {adultNo: 1, childNo: 0, infantNo: 0},
        stops: false,
        class: "economy",
        flightType: "round",
    });

    /*Handling Date Logic Start*/
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");

    useEffect(
        () =>
            setFlightData({...flightData, departingOn: fromDate, returnOn: toDate}),
        [toDate, fromDate]
    );

    const handleToDate = (e) => setToDate(e.detail);
    const handleFromDate = (e) => setFromDate(e.detail);

    useEffect(() => {
        document.addEventListener("from-date", handleFromDate);
        document.addEventListener("to-date", handleToDate);

        return () => {
            document.removeEventListener("from-date", handleFromDate);
            document.removeEventListener("to-date", handleToDate);
        };
    }, []);
    /*Handling Date Logic End*/

    useEffect(() => {
        if (localStorage.hasOwnProperty("flight_details")) {
            const searchData = JSON.parse(localStorage.getItem("flight_details"));
            setFlightData(searchData);
        }
    }, []);

    const getLocationValue = (location) => {
        if (typeof location === "object") {
            return `${location.name} (${location.iataname})`;
        } else return location;
    };
    const getTravellersValue = (travelers) => {
        const {adultNo, infantNo, childNo} = travelers;

        if (infantNo === 0 && childNo === 0) {
            if (adultNo === 1) {
                return `${adultNo} Adult`;
            } else {
                return `${adultNo} Adults`;
            }
        } else {
            return `${adultNo + infantNo + childNo} Travelers`;
        }
    };

    /*Extra*/
    // const handleDate = async (key, date) => setFlightData({...flightData, [key]: date});
    // const handleLocation = (key, location) => () => dispatch(setFlightLocation({key, location}));
    // const handleFlightType = (e) => setFlightData({...flightData, flightType: e.target.value});

    const handleChange = (event, key, value) => {
        /*handle Traveller onChange when event = traveller*/
        if (event === "traveler") {
            setFlightData({
                ...flightData,
                travelers: {
                    ...flightData["travelers"],
                    [key]: flightData["travelers"][key] + value,
                },
            });
        } else if (event) {
            /*handle default onChange event*/
            const {name, value} = event.target;
            setFlightData({...flightData, [name]: value});
        } else {
            /*handle onChange with provide key & value*/
            setFlightData({...flightData, [key]: value});
        }
    };

    const {departingOn, returnOn, locationArr, locationDep, travelers} =
        flightData;
    const returningParam = flightData.flightType !== "one-way";
    const returningDateParam = returnOn && returningParam ? returnOn : false;

    const getURI = () => {
        const departureIATA = locationDep.iataname;
        const arrivalIATA = locationArr?.iataname
        const flightType = flightData.flightType;
        const {childNo, adultNo, infantNo} = travelers;
        return `/search-flights?departingOn=${departingOn}&locationDep=${departureIATA}&locationArr=${arrivalIATA}&return=${returningParam}&returningOn=${returningDateParam}&adultNo=${adultNo}&childNo=${childNo}&infantNo=${infantNo}&flightType=${flightType}`;
    }

    return [
        flightData,
        handleChange,
        getLocationValue,
        getTravellersValue,
        getURI,
    ];
};

export default useFlight;
