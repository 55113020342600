import React, {useEffect, useState} from "react";

const AutoComplete = ({data, name, onChange, placeholder, to = false, label, required, defaultValue}) => {

    const [suggestions, setSuggestions] = useState([]);
    const [suggestionIndex, setSuggestionIndex] = useState(0);
    const [suggestionsActive, setSuggestionsActive] = useState(false);
    const [value, setValue] = useState('');

    useEffect(() => setValue(defaultValue), [defaultValue]);

    const handleChange = (e) => {
        const query = e.target.value.toUpperCase();
        setValue(query);
        if (query.length > 1) {
            const filterSuggestions = data.filter(
                (suggestion) => {
                    /*Here suggestion are the cities*/
                    if (typeof query === 'string') {
                        /*Here suggestion are the cities*/

                        const {name, iataname} = suggestion;

                        const PATTERN = query.toUpperCase();

                        const isMatch = name.toUpperCase().indexOf(PATTERN) > -1
                            || iataname.toUpperCase().indexOf(PATTERN) > -1
                        // || airport.toUpperCase().indexOf(PATTERN) > -1

                        if (isMatch)
                            return suggestion;
                    }
                }
            );
            setSuggestions(filterSuggestions);
            setSuggestionsActive(true);
        } else {
            setSuggestionsActive(false);
        }
    };

    const getLocationValue = (location) => {
        if (typeof location === 'object') {
            return `${location.name} (${location.iataname})`
        } else
            return location;
    }

    const handleClick = (_, name, location) => {
        setSuggestions([]);
        setValue(location);
        onChange(_, name, location);
        setSuggestionsActive(false);
    };

    const handleKeyDown = (e) => {
        // UP ARROW
        if (e.keyCode === 38) {
            if (suggestionIndex === 0) {
                return;
            }
            setSuggestionIndex(suggestionIndex - 1);
        }
        // DOWN ARROW
        else if (e.keyCode === 40) {
            if (suggestionIndex - 1 === suggestions.length) {
                return;
            }
            setSuggestionIndex(suggestionIndex + 1);
        }
        // ENTER
        else if (e.keyCode === 13) {
            setValue(suggestions[suggestionIndex]);
            setSuggestionIndex(0);
            setSuggestionsActive(false);
        }
    };

    const Suggestions = () => {
        return (
            <div className='selector-box d-block'>
                <ul>
                    {suggestions.map((suggestion, index) => {
                        return (
                            <li
                                className='py-2'
                                key={index}
                                onClick={(e) => handleClick(null, name, suggestion)}
                                role='button'
                            >
                                <div>
                                    <h5>{suggestion.name.toUpperCase()}</h5>
                                    {/*<h6>{suggestion.airport}</h6>*/}
                                    <span>{suggestion.iataname}</span>
                                </div>
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    };

    return (
        <div className="form-group">
            {label && <label>{label}</label>}
            <input
                type="text" className="form-control open-select"
                name={name}
                value={getLocationValue(value)}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                placeholder={placeholder}
                autoComplete='off'
                required={required}
            />
            <img src={`./assets/images/icon/${to ? 'location' : 'from'}.png`}
                 className="img-fluid blur-up lazyload" alt=""/>
            {suggestionsActive && <Suggestions/>}
        </div>
    )
}


export default AutoComplete