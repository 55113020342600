import React from 'react'
import {getCurrency, numberWithComma} from "utils";
import $ from 'jquery';
import {valuesIn} from "../utils/objects";

const FareSummary = ({summary, coupon}) => {
    const currencyCode = getCurrency();

    const grandTotal = numberWithComma(
        valuesIn(summary).reduce((acc, {total}) => acc + total, 0)
    )

    const applyCoupon = (e) => {
        e.preventDefault();
        alert("Coupon is not valid");
    }

    const collapseHandler = () => {
        const w = $('.transition-expand');
        if (w.hasClass('open')) {
            w.removeClass('open');
            w.animate({height: 0})
        } else {
            w.addClass('open');
            w.removeAttr('style')
        }
    }
    return (
        <div className="summery_box">
            <div className='section-res-booking'>
                <div className="section-content">
                    <ul className="flight-blocks">
                        {
                            valuesIn(summary).map((summary, index) => {
                                // console.log(summary);
                                return (
                                    <li className="flight-block" key={index}>
                                        <div className="flight-summary">
                                            <div className="flight-leg">
                                                <div className="flight-leg-info">
                                                    <h5 className="route">{summary['route']}</h5>
                                                    <div className="cities">
                                                        <strong className="origin">{summary['origin']}</strong>
                                                        <span> to </span>
                                                        <strong
                                                            className="destination">{summary['destination']}</strong>
                                                    </div>
                                                    <div className="times">
                                                        <strong className="leaving">{summary['leaving']}</strong>
                                                        <span>-</span>
                                                        <strong className="landing">{summary['landing']}</strong>
                                                    </div>
                                                </div>
                                                <div className="flight-leg-info">
                                                    <h5 className="aircraft">{summary['aircraft']}</h5>
                                                    <div className="date">{summary['date']}</div>
                                                    <div className="number"><span>{summary['number']}</span></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="fare-summary">
                                            <div className="transition-expand">
                                                <div className="transition-expand-content">
                                                    <table cellPadding="0" cellSpacing="0" border="0">
                                                        <thead>
                                                        <tr>
                                                            <th className="type">Guest Type</th>

                                                            <th className="fare">Fare</th>
                                                            <th className="tax-fees">Tax/Fees</th>
                                                            <th className="total">Total</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr className="spacer">
                                                            <td colSpan="4"/>
                                                        </tr>
                                                        {valuesIn(summary['fareSummary']).map((passengerFare, index) => (
                                                            <tr className="has-taxes" key={index}>
                                                                <td className="type">
                                                                    <span>{passengerFare['type']}</span></td>
                                                                <td className="fare">
                                                                    <span>{passengerFare['fare']}</span></td>
                                                                <td className="tax-fees">
                                                                    <span>{passengerFare['taxFees']}</span></td>
                                                                <td className="total">
                                                                    <span><strong>{passengerFare['total']}</strong></span>
                                                                </td>
                                                            </tr>
                                                        ))}

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <h5 className="block-total active" onClick={collapseHandler}>
                                                <strong>PKR {numberWithComma(summary['total'])}</strong>
                                                <span className="toggle-action">
                                        {/*<i className='fa fa-arrow-up'/>*/}
                                    </span>
                                            </h5>
                                        </div>
                                    </li>
                                )
                            })
                        }
                    </ul>
                    <div className="costs-and-total total-cost pt-3">
                        <div className="grand_total m-0">
                            <h5>grand total:
                                <span> {currencyCode} {grandTotal}</span>
                            </h5>
                        </div>
                    </div>

                    {/*<div className="mb-3 form-check">
                        <input type="checkbox" className="form-check-input me-2" id="exampleCheck1"/>
                        <label className="form-check-label" htmlFor="exampleCheck1">
                            I accept the</label> <span>Terms &amp; Conditions</span>
                    </div>*/}
                </div>
            </div>


            {coupon && (
                <div className="form-group mb-0 my-2">
                    <label>have a coupon code?</label>
                    <form className="input-group" onSubmit={applyCoupon}>
                        <input type="text" className="form-control"
                               placeholder="FLY50" required/>
                        <button className='border-0 bg-transparent' type='submit'>
                            <div className="input-group-prepend">
                                <span className="input-group-text">apply</span>
                            </div>
                        </button>
                    </form>
                </div>
            )}
        </div>
    )
};

export default FareSummary;
