import React from 'react'

const FlightTrack = ({from = '', to = '', type = ''}) => {
    return (
        <div className='d-flex align-items-center justify-content-md-start  flex-column flex-md-row my-3'>
            <h4 className='text-muted text-uppercase'>Select {type} Flight</h4>
            <div>
                <span> ({from} </span>
                <span><i className="fas fa-long-arrow-alt-right mx-2"/></span>
                <span> {to}) </span>
            </div>
        </div>
    )
};

export default FlightTrack;
