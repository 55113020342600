import React, {Fragment, useState} from 'react'
import {useScript} from "../hooks";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useMutation} from "react-query";
import apis from "../services";
import {Alert} from "../components";
import {setUser} from "../redux/actions/user";

const SignInPage = () => {
    useScript('script.js');

    const dispatch = useDispatch();
    const navigation = useNavigate();
    const {state} = useLocation();
    const previousLocation = state ? state.from : '/dashboard';
    const handleNavigation = () => navigation(previousLocation)


    const [formState, setFormState] = useState({email: '', password: '', remember: false});
    const [error, setError] = useState({status: false, errors: [], role: 'danger'})

    const {user} = useSelector(store => store.user);


    const {isLoading, mutate, data} = useMutation('login', apis.loginApi, {
        onError: (error) => console.log(error),
        onSuccess: async ({data}) => {
            if (data.status === false)
                setError({role: 'danger', errors: data.errors, status: true});
            else {
                await dispatch(setUser(data?.data));
                handleNavigation();
            }
        }
    });

    const handleOnChange = (e) => {
        const {id, value} = e.target;
        setFormState({...formState, [id]: value})
    }

    const handleFacebookLogin = () => {
        alert('Facebook Login')
    }

    const handleGoogleLogin = () => {
        alert('Google Login')
    }

    const handleLogin = (e) => {
        e.preventDefault();
        mutate(formState);
    }

    console.log(error);

    if (user) return navigation('/');

    return (
        <Fragment>
            {/*<Breadcrumb/>*/}
            <section className="section-b-space dark-cls animated-section">
                <img src="../assets/images/cab/grey-bg.jpg" alt="" className="img-fluid blur-up lazyload bg-img"/>
                <div className="animation-section">
                    <div className="cross po-1"/>
                    <div className="cross po-2"/>
                    <div className="round po-4"/>
                    <div className="round po-5"/>
                    <div className="round r-y po-8"/>
                    <div className="square po-10"/>
                    <div className="square po-11"/>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="offset-lg-3 col-lg-6 offset-sm-2 col-sm-8 col-12">
                            <div className="account-sign-in">
                                <div className="title">
                                    <h3>Login</h3>
                                </div>
                                <div className="login-with">
                                    <h6>log in with</h6>
                                    <div className="login-social row">
                                        <div className="col" onClick={handleFacebookLogin}>
                                            <a className="boxes">
                                                <img src="../assets/images/icon/social/facebook.png"
                                                     className="img-fluid blur-up lazyload" alt=""/>
                                                <h6>facebook</h6>
                                            </a>
                                        </div>
                                        <div className="col" onClick={handleGoogleLogin}>
                                            <a className="boxes">
                                                <img src="../assets/images/icon/social/google.png"
                                                     className="img-fluid blur-up lazyload" alt=""/>
                                                <h6>google</h6>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="divider">
                                        <h6>OR</h6>
                                    </div>
                                </div>
                                <Alert error={error} setError={setError}/>

                                <form onSubmit={handleLogin}>
                                    <div className="form-group">
                                        <label htmlFor="email">user name or Email address</label>
                                        <input type="email" className="form-control" id="email"
                                               aria-describedby="emailHelp" placeholder="Enter email"
                                               value={formState.email} onChange={handleOnChange}
                                        />
                                        <small id="emailHelp" className="form-text text-muted">We'll never share
                                            your email with
                                            anyone else.</small>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="password">Password</label>
                                        <input type="password" className="form-control" id="password"
                                               placeholder="Password" value={formState.password}
                                               onChange={handleOnChange}
                                        />
                                    </div>
                                    <div className="form-group form-check">
                                        <input type="checkbox" className="form-check-input" id="remember"
                                               value={formState.remember}
                                               onChange={({target}) => setFormState({
                                                   ...formState,
                                                   remember: target.checked
                                               })}/>
                                        <label className="form-check-label" htmlFor="remember">
                                            remember me</label>
                                    </div>
                                    <div className="button-bottom">
                                        <button type="submit" className="w-100 btn btn-solid" disabled={isLoading}>
                                            {isLoading ? 'Signing-in' : 'Sign in'}
                                        </button>
                                        <div className="divider">
                                            <h6>or</h6>
                                        </div>
                                        <Link className="w-100 btn btn-solid btn-outline" to='/sign-up'>
                                            create account
                                        </Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

export default SignInPage
