const isEmpty = (obj = {}) => Object.keys(obj).length === 0;

const keysIn = (obj = {}) => {
    if (isEmpty(obj)) return [];

    return Object.keys(obj);
}


const valuesIn = (obj = {}) => {
    if (isEmpty(obj)) return [];

    return Object.values(obj);
}


const toPairsIn = (obj = {}) => {
    if (isEmpty(obj)) return [];

    // console.log(...Object.entries(obj));
    return Object.entries(obj);
}

const valuesInLength = (obj = {}) => {
    if (isEmpty(obj)) return 0;

    return valuesIn(obj).length;
}

const keysInLength = (obj = {}) => {
    if (isEmpty(obj)) return 0;

    return keysIn(obj).length;
}

export {keysIn, toPairsIn, valuesIn, valuesInLength, keysInLength}