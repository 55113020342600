/*==========    Find Flights Actions    ==========*/
export const SET_TRAVELERS = 'SET_TRAVELERS'
export const SAVE_PNR = 'SAVE_PNR';

export const ADD_SELECTED_FLIGHT = 'ADD_SELECTED_FLIGHT';
export const CLEAR_SELECTED_FLIGHTS = 'CLEAR_SELECTED_FLIGHTS';
export const CLEAR_SELECTED_FLIGHT = 'CLEAR_SELECTED_FLIGHT'


export const SET_USER = 'SET_USER'
export const CLEAR_USER = 'CLEAR_USER'


/*==========    Travelers Actions    ==========*/

export const SET_TRAVELER_DATA = 'SET_TRAVELER_DATA';
export const CLEAR_TRAVELER_DATA = 'CLEAR_TRAVELER_DATA';

export const SAVE_BOOKING_ID = 'SAVE_BOOKING_ID';

export const RESET_FLIGHT_DATA = 'RESET_FLIGHT_DATA';