import React from 'react'

const Offer = () => {
    return (
        <section className="menu-section section-b-space">
            <div className="container">
                <div className="title-1 title-5">
                    <span className="title-label">Chaloje</span>
                    <h2>best deals</h2>
                    <p>View all live offers for your flight bookings</p>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="slide-3 no-arrow">
                            <div>
                                <div className="menu-box">
                                    <div className="top-bar">
                                        <img src="../assets/images/flights/offer/3.jpg"
                                             className="img-fluid blur-up lazyload"
                                             alt=""/>
                                        <h2>emirates</h2>
                                        <div className="decorate">emirates</div>
                                    </div>
                                    <div className="bottom-bar">
                                        <div className="menu-bar">
                                            <a href="/#"><img
                                                src="../assets/images/flights/offer/6.jpg"
                                                className="img-fluid blur-up lazyload" alt=""/></a>
                                            <div className="content">
                                                <a href="/#">
                                                    <h5>norway</h5>
                                                </a>
                                                <p>Dep: paris, dubai</p>
                                                <h6>
                                                    <del>$250</del>
                                                    $200
                                                </h6>
                                            </div>
                                        </div>
                                        <div className="menu-bar">
                                            <a href="/#"><img
                                                src="../assets/images/flights/offer/7.jpg"
                                                className="img-fluid blur-up lazyload" alt=""/></a>
                                            <div className="content">
                                                <a href="/#">
                                                    <h5>finland</h5>
                                                </a>
                                                <p>Dep: paris, dubai</p>
                                                <h6>
                                                    <del>$250</del>
                                                    $200
                                                </h6>
                                            </div>
                                        </div>
                                        <div className="menu-bar">
                                            <a href="/#"> <img
                                                src="../assets/images/flights/offer/8.jpg"
                                                className="img-fluid blur-up lazyload" alt=""/></a>
                                            <div className="content">
                                                <a href="/#">
                                                    <h5>paris</h5>
                                                </a>
                                                <p>Dep: finland, dubai</p>
                                                <h6>
                                                    <del>$250</del>
                                                    $200
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="menu-box">
                                    <div className="top-bar">
                                        <a href="/#"><img src="../assets/images/flights/offer/4.jpg"
                                                         className="img-fluid blur-up lazyload" alt=""/></a>
                                        <h2>american eagle</h2>
                                        <div className="decorate">american eagle</div>
                                    </div>
                                    <div className="bottom-bar">
                                        <div className="menu-bar">
                                            <a href="/#"><img
                                                src="../assets/images/flights/offer/9.jpg"
                                                className="img-fluid blur-up lazyload" alt=""/></a>
                                            <div className="content">
                                                <a href="/#">
                                                    <h5>dubai</h5>
                                                </a>
                                                <p>Dep: paris, london</p>
                                                <h6>
                                                    <del>$250</del>
                                                    $200
                                                </h6>
                                            </div>
                                        </div>
                                        <div className="menu-bar">
                                            <a href="/#"><img
                                                src="../assets/images/flights/offer/10.jpg"
                                                className="img-fluid blur-up lazyload" alt=""/></a>
                                            <div className="content">
                                                <a href="/#">
                                                    <h5>new york</h5>
                                                </a>
                                                <p>Dep: paris, dubai</p>
                                                <h6>
                                                    <del>$250</del>
                                                    $200
                                                </h6>
                                            </div>
                                        </div>
                                        <div className="menu-bar">
                                            <a href="/#"><img
                                                src="../assets/images/flights/offer/6.jpg"
                                                className="img-fluid blur-up lazyload" alt=""/></a>
                                            <div className="content">
                                                <a href="/#">
                                                    <h5>norway</h5>
                                                </a>
                                                <p>Dep: paris, dubai</p>
                                                <h6>
                                                    <del>$250</del>
                                                    $200
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="menu-box">
                                    <div className="top-bar">
                                        <a href="/#"><img src="../assets/images/flights/offer/5.jpg"
                                                         className="img-fluid blur-up lazyload" alt=""/></a>
                                        <h2>Lufthansa</h2>
                                        <div className="decorate">Lufthansa</div>
                                    </div>
                                    <div className="bottom-bar">
                                        <div className="menu-bar">
                                            <a href="/#"><img
                                                src="../assets/images/flights/offer/8.jpg"
                                                className="img-fluid blur-up lazyload" alt=""/></a>
                                            <div className="content">
                                                <a href="/#">
                                                    <h5>paris</h5>
                                                </a>
                                                <p>Dep: paris, dubai</p>
                                                <h6>
                                                    <del>$250</del>
                                                    $200
                                                </h6>
                                            </div>
                                        </div>
                                        <div className="menu-bar">
                                            <a href="/#"><img
                                                src="../assets/images/flights/offer/6.jpg"
                                                className="img-fluid blur-up lazyload" alt=""/></a>
                                            <div className="content">
                                                <a href="/#">
                                                    <h5>norway</h5>
                                                </a>
                                                <p>Dep: paris, dubai</p>
                                                <h6>
                                                    <del>$250</del>
                                                    $200
                                                </h6>
                                            </div>
                                        </div>
                                        <div className="menu-bar">
                                            <a href="/#"><img
                                                src="../assets/images/flights/offer/10.jpg"
                                                className="img-fluid blur-up lazyload" alt=""/></a>
                                            <div className="content">
                                                <a href="/#">
                                                    <h5>new york</h5>
                                                </a>
                                                <p>Dep: paris, dubai</p>
                                                <h6>
                                                    <del>$250</del>
                                                    $200
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="menu-box">
                                    <div className="top-bar">
                                        <a href="/#"><img src="../assets/images/flights/offer/4.jpg"
                                                         className="img-fluid blur-up lazyload" alt=""/></a>
                                        <h2>american eagle</h2>
                                        <div className="decorate">american eagle</div>
                                    </div>
                                    <div className="bottom-bar">
                                        <div className="menu-bar">
                                            <a href="/#"><img
                                                src="../assets/images/flights/offer/9.jpg"
                                                className="img-fluid blur-up lazyload" alt=""/></a>
                                            <div className="content">
                                                <a href="/#">
                                                    <h5>dubai</h5>
                                                </a>
                                                <p>Dep: paris, london</p>
                                                <h6>
                                                    <del>$250</del>
                                                    $200
                                                </h6>
                                            </div>
                                        </div>
                                        <div className="menu-bar">
                                            <a href="/#"><img
                                                src="../assets/images/flights/offer/10.jpg"
                                                className="img-fluid blur-up lazyload" alt=""/></a>
                                            <div className="content">
                                                <a href="/#">
                                                    <h5>new york</h5>
                                                </a>
                                                <p>Dep: paris, dubai</p>
                                                <h6>
                                                    <del>$250</del>
                                                    $200
                                                </h6>
                                            </div>
                                        </div>
                                        <div className="menu-bar">
                                            <a href="/#"><img
                                                src="../assets/images/flights/offer/6.jpg"
                                                className="img-fluid blur-up lazyload" alt=""/></a>
                                            <div className="content">
                                                <a href="/#">
                                                    <h5>norway</h5>
                                                </a>
                                                <p>Dep: paris, dubai</p>
                                                <h6>
                                                    <del>$250</del>
                                                    $200
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
};

export default Offer;