import React, {Fragment} from "react";
import {formatDate, getAirlineImage} from "utils";
import {validateDate} from "../utils/formatDate";


const FlightTicketDetails = ({flight}) => {
    const {AIRLINE, DEPARTURE_TIME, ORGN, DURATION, ARRIVAL_TIME, DEST} = flight;

    const date = AIRLINE === 'Airsial'
        ? validateDate(flight['DEPARTURE_DATE'], 'MMMM Do YYYY')
        : formatDate(flight['DEPARTURE_DATE'], 'MMMM Do YYYY');

    return (
        <Fragment>
            <div className="flight_detail">
                <div className="row">
                    <div className="col-md-3">
                        <div className="logo-sec">
                            <img
                                src={getAirlineImage(AIRLINE)}
                                className="img-fluid blur-up lazyload"
                                alt=""
                            />
                            <span className="title">{AIRLINE}<br/>
                            </span>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="airport-part">
                            <div className="airport-name">
                                <h4>{DEPARTURE_TIME}</h4>
                                <h6>{ORGN}</h6>
                                {/*<h6>{departAirport.code}<span>{departTime}</span></h6>*/}
                            </div>
                            <div className="airport-progress">
                                <i className="fas fa-plane-departure float-start"/>
                                <i className="fas fa-plane-arrival float-end"/>
                            </div>
                            <div className="airport-name arrival">
                                <h4>{ARRIVAL_TIME}</h4>
                                <h6>{DEST}</h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="duration">
                            <div>
                                <h6>{DURATION}</h6>
                                <b>{date}</b>
                                {/*<p>(1 stop)</p>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default FlightTicketDetails;
