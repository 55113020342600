import React, {Fragment} from 'react'
import {Breadcrumb} from "../components/sections";

const ComingSoon = () => {
    return (
        <Fragment>
            <Breadcrumb/>
            <div className='d-flex justify-content-center align-items-center min-vh-100'>
                <h1>Coming Soon</h1>
            </div>
        </Fragment>
    )
}

export default ComingSoon
