/*https://airhex.com/airline-logos/*/
const getAirlineImage = (airline) => {
    switch (airline) {
        case 'Airsial':
        case 'AirSial':
        case 'Air Sial':
        case 'Air sial':
            return 'https://s3.ap-south-1.amazonaws.com/st-airline-images/PF.png'
        case 'Airblue':
        case 'AirBlue':
        case 'Air blue':
        case 'Air Blue':
            return 'https://s3.ap-south-1.amazonaws.com/st-airline-images/PA.png'
        case 'Air Serene':
        case 'Air serene':
        case 'AirSerene':
        case 'Airserene':
            return 'https://s3.ap-south-1.amazonaws.com/st-airline-images/ER.png'
        case 'Fly Jinnah':
        case 'Fly jinnah':
        case 'FlyJinnah':
        case 'Flyjinnah':
            return 'https://s3.ap-south-1.amazonaws.com/st-airline-images/9P.png'
        default:
            return '';

    }
}

export default getAirlineImage;
