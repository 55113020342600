import React from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {clearUser} from "redux/actions/user";

const Header = () => {
    const {pathname} = useLocation();
    const navigation = useNavigate();
    const dispatch = useDispatch();
    const isHomePage = ['/', '/manage-booking'].includes(pathname);
    const headerClassName = isHomePage ? "overlay-black" : "light_header";

    const {user} = useSelector((store) => store.user);

    const handleLogout = () => {
        dispatch(clearUser());
        navigation("/");
    };

    return (
        <header className={headerClassName}>
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="menu">
                            <div className="brand-logo">
                                <Link to="/">
                                    <img
                                        src="./assets/images/icon/chaloje.png"
                                        alt=""
                                        className="img-fluid blur-up lazyload"
                                        style={{width: " 150px", filter: "inherit"}}
                                    />
                                </Link>
                            </div>
                            <nav>
                                <div className="main-navbar">
                                    <div id="mainnav">
                                        <div className="toggle-nav">
                                            <i className="fa fa-bars sidebar-bar"/>
                                        </div>
                                        <div className="menu-overlay"/>
                                        <ul className="nav-menu">
                                            <li className="back-btn">
                                                <div className="mobile-back text-end">
                                                    <span>Back</span>
                                                    <i
                                                        aria-hidden="true"
                                                        className="fa fa-angle-right ps-2"
                                                    />
                                                </div>
                                            </li>
                                            <li className="dropdown">
                                                <Link className="nav-link menu-title" to="/">
                                                    Home
                                                </Link>
                                            </li>
                                            <li className="dropdown">
                                                <Link
                                                    className="nav-link menu-title"
                                                    to="/search-flights"
                                                >
                                                    flight
                                                </Link>
                                            </li>
                                            <li className="dropdown">
                                                <Link
                                                    className="nav-link menu-title"
                                                    to="/manage-booking"
                                                >
                                                    Manage Booking
                                                </Link>
                                            </li>
                                            {/*<li className="dropdown">
                                                <Link className="nav-link menu-title" to="/hotels">
                                                    Hotels
                                                </Link>
                                            </li>
                                            <li className="dropdown">
                                                <Link className="nav-link menu-title" to="/packages">
                                                    Packages
                                                </Link>
                                            </li>*/}
                                            <li className="dropdown">
                                                <Link className="nav-link menu-title" to="/contact">
                                                    Contact
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </nav>
                            <ul className="header-right nav-menu">
                                {/*<li className="front-setting">
                                    <select>
                                        <option value="volvo">USD</option>
                                        <option value="saab">EUR</option>
                                        <option value="opel">INR</option>
                                        <option value="audi">AUD</option>
                                    </select>
                                </li>
                                <li className="front-setting">
                                    <select>
                                        <option value="volvo">ENG</option>
                                        <option value="saab">FRE</option>
                                        <option value="opel">SPA</option>
                                        <option value="audi">DUT</option>
                                    </select>
                                </li>*/}
                                <div className="dropdown">
                                    <a
                                        className="user "
                                        href="#"
                                        role="button"
                                        style={{
                                            backgroundColor: "transparent",
                                            color: isHomePage ? "#FFF" : "#000",
                                        }}
                                        id="dropdownMenuLink"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        <h6>
                                            <i
                                                className="fas fa-user mx-2"
                                                style={{color: "inherit"}}
                                            />
                                            {user && user.full_name}
                                        </h6>
                                    </a>

                                    <ul className="dropdown-menu">
                                        {user ? (
                                            <>
                                                <li>
                                                    <Link className="dropdown-item" to="/dashboard">
                                                        Dashboard
                                                    </Link>
                                                </li>
                                                <li
                                                    className="dropdown-item"
                                                    type="button"
                                                    onClick={handleLogout}
                                                >
                                                    Logout
                                                </li>
                                            </>
                                        ) : (
                                            <>
                                                <li>
                                                    <Link className="dropdown-item" to="/sign-in">
                                                        Sign in
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link className="dropdown-item" to="/sign-up">
                                                        Sign up
                                                    </Link>
                                                </li>
                                            </>
                                        )}
                                    </ul>
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
