import React, {Fragment, useEffect} from 'react'
import $ from "jquery"
import {useFetchCities, useScript} from "hooks";
import {useNavigate} from 'react-router-dom';
import {Helmet} from "react-helmet";
import AutoComplete from "../AutoComplete";


const FlightSection = ({flights}) => {
    useScript('datepicker.js');

    const navigate = useNavigate();
    const [flightData, handleChange, getLocationValue, getTravellersValue, getURI] = flights;

    const [fetchingCities, cities] = useFetchCities(flightData.locationDep || flightData.locationArr);

    const IS_ONEWAY_TRIP = flightData.flightType === 'one-way';

    const searchHandler = async (event) => {
        localStorage.setItem('flight_details', JSON.stringify(flightData));
        event.preventDefault();
        navigate(getURI())
    }

    const handleTravelerMinusButton = (type) => {
        const {adultNo, childNo, infantNo} = flightData.travelers;
        const notAllowedStyle = {pointerEvents: 'none', backgroundColor: 'lightgray'};
        if (type === 'adult' && adultNo === 1)
            return notAllowedStyle;

        if (type === 'child' && childNo === 0)
            return notAllowedStyle;

        if (type === 'infant' && infantNo === 0)
            return notAllowedStyle;

        return {}
    }

    const handleTravelerPlusButton = (type) => {
        const {adultNo, infantNo, childNo} = flightData.travelers;
        const notAllowedStyle = {pointerEvents: 'none', backgroundColor: 'lightgray'};

        if (type === 'adult' && adultNo === 6)
            return notAllowedStyle;

        if (type === 'child' && childNo === 6)
            return notAllowedStyle;

        if (type === 'infant' && infantNo === 6)
            return notAllowedStyle;

        return {}
    }

    useEffect(() => {

        $(".open-select").click(function () {
            $(this).parent().find(".selector-box, .selector-box-flight").addClass("show");
        });


        $('.selector-box li, .bottom-part > a').click(function (e) {
            $(".selector-box, .selector-box-flight, .calender-external, .setting-open").removeClass("show")
        });
    }, []);


    return (
        <Fragment>
            <Helmet>
                <script src="./assets/js/script.js"/>
            </Helmet>
            <section className="cab-section flight-section home-section p-0" style={{overflow: "inherit"}}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="flight-left h-100">
                                <img src="./assets/images/flights/plane-1.png"
                                     className="img-fluid blur-up lazyload plane-animation" alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="cab-content">
                                <div>
                                    <div className="top-cls">
                                        #chaloje
                                    </div>
                                    <h2>great journey begins</h2>
                                    <h3>with a small step</h3>
                                    <form className="radio-form">
                                        <input id="radio-1" type="radio" name="flightType" value="round"
                                               onChange={handleChange}
                                               defaultChecked={flightData.flightType === 'round'}/>
                                        <label htmlFor="radio-1" className="radio-label">round trip</label>
                                        <input id="radio-2" type="radio" name="flightType" value='one-way'
                                               onChange={handleChange}
                                               defaultChecked={flightData.flightType === 'one-way'}/>
                                        <label htmlFor="radio-2" className="radio-label">one way</label>

                                    </form>
                                    <form onSubmit={searchHandler}>
                                        <AutoComplete name='locationDep' onChange={handleChange} data={cities}
                                                      placeholder='From' required
                                                      defaultValue={flightData.locationDep}/>
                                        <AutoComplete name='locationArr' onChange={handleChange} data={cities}
                                                      placeholder='To' to required
                                                      defaultValue={flightData.locationArr}/>
                                        <div className="form-group mb-0">
                                            <div className="row">
                                                <div className="col">
                                                    <input placeholder="Depart Date" id="datepicker" autoComplete='off'
                                                           defaultValue={flightData.departingOn} required
                                                    />
                                                </div>

                                                <div id="drop_date"
                                                     className={`col ${!IS_ONEWAY_TRIP ? 'block' : 'd-none'}`}>
                                                    <input placeholder="Return Date" id="datepicker1" autoComplete='off'
                                                           defaultValue={flightData.returnOn}
                                                           required={flightData.flightType === 'round'}
                                                    />
                                                </div>

                                                <div className="col">
                                                    <div className="form-group">
                                                        <input type="text" className="form-control open-select"
                                                               placeholder="traveller"
                                                               value={getTravellersValue(flightData.travelers)}/>
                                                        <img src="./assets/images/icon/user.png"
                                                             className="img-fluid blur-up lazyload" alt=""/>
                                                        <div className="selector-box-flight">
                                                            <div className="room-cls">
                                                                <div className="qty-box">
                                                                    <label>adult</label>
                                                                    <div className="input-group">
                                                                        <button type="button"
                                                                                className="btn quantity-left-minus"
                                                                                onClick={() => handleChange('traveler', 'adultNo', -1)}
                                                                                style={handleTravelerMinusButton('adult')}
                                                                        > -
                                                                        </button>
                                                                        <input type="text" name="adult"
                                                                               className="form-control qty-input input-number"
                                                                               value={flightData.travelers.adultNo}
                                                                        />
                                                                        <button type="button"
                                                                                className="btn quantity-right-plus"
                                                                                onClick={() => handleChange('traveler', 'adultNo', 1)}
                                                                                style={handleTravelerPlusButton('adult')}
                                                                        >+
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <div className="qty-box">
                                                                    <label>children</label>
                                                                    <div className="input-group">
                                                                        <button type="button"
                                                                                className="btn quantity-left-minus"
                                                                                onClick={() => handleChange('traveler', 'childNo', -1)}
                                                                                style={handleTravelerMinusButton('child')}
                                                                        > -
                                                                        </button>
                                                                        <input type="text" name="quantity"
                                                                               className="form-control qty-input input-number"
                                                                               value={flightData.travelers.childNo}/>
                                                                        <button type="button"
                                                                                className="btn quantity-right-plus"
                                                                                onClick={() => handleChange('traveler', 'childNo', 1)}
                                                                                style={handleTravelerPlusButton('child')}
                                                                        > +
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <div className="qty-box">
                                                                    <label>infants</label>
                                                                    <div className="input-group">
                                                                        <button type="button"
                                                                                className="btn quantity-left-minus"
                                                                                onClick={() => handleChange('traveler', 'infantNo', -1)}
                                                                                style={handleTravelerMinusButton('infant')}
                                                                        > -
                                                                        </button>
                                                                        <input type="text" name="quantity"
                                                                               className="form-control qty-input input-number"
                                                                               value={flightData.travelers.infantNo}/>
                                                                        <button type="button"
                                                                                className="btn quantity-right-plus"
                                                                                onClick={() => handleChange('traveler', 'infantNo', 1)}
                                                                                style={handleTravelerPlusButton('infant')}
                                                                        > +
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="flight-class">
                                                                <div className="form-check">
                                                                    <input
                                                                        className="form-check-input radio_animated"
                                                                        type="radio"
                                                                        id="economy"
                                                                        name="class"
                                                                        value="economy"
                                                                        onChange={handleChange}
                                                                        checked={flightData.class === 'economy'}/>
                                                                    <label className="form-check-label"
                                                                           htmlFor="economy">
                                                                        economy
                                                                    </label>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input
                                                                        className="form-check-input radio_animated"
                                                                        type="radio"
                                                                        onChange={handleChange}
                                                                        name="class"
                                                                        value="premium"
                                                                        checked={flightData.class === 'premium'}
                                                                    />
                                                                    <label className="form-check-label"
                                                                           htmlFor="premium">
                                                                        premium
                                                                    </label>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input
                                                                        className="form-check-input radio_animated"
                                                                        type="radio"
                                                                        onChange={handleChange}
                                                                        name="class" id="business"
                                                                        value="business"
                                                                        checked={flightData.class === 'business'}
                                                                    />
                                                                    <label className="form-check-label"
                                                                           htmlFor="business">
                                                                        business
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="bottom-part">
                                                                <a href="/#"
                                                                   className="btn">apply</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <button type="submit" className="btn btn-rounded btn-sm color1 float-end">
                                            search now
                                        </button>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
};

export default FlightSection;
