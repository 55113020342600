import React, {Fragment} from 'react'
import {Breadcrumb} from "../components/sections";

const ContactPage = () => {
    return (
        <Fragment>
            <Breadcrumb/>
            <section className="contact_section small-section pb-0">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-sm-6">
                            <div className="contact_wrap">
                                <div className="title_bar">
                                    <i className="fas fa-map-marker-alt"/>
                                    <h4>Address</h4>
                                </div>
                                <div className="contact_content">
                                    <p>Office No. 204, Plot No.66, 2nd Floor, Iconic Trade Center, Behind Medicare
                                        Hospital, Bihar Muslim Society Block 3 Sharfabad, Karachi, Karachi City,
                                        Sindh.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <div className="contact_wrap">
                                <div className="title_bar">
                                    <i className="fas fa-envelope"/>
                                    <h4>email address</h4>
                                </div>
                                <div className="contact_content">
                                    <ul>
                                        <li>support@chaloje.com</li>
                                        <li>info@chaloje.comm</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <div className="contact_wrap">
                                <div className="title_bar">
                                    <i className="fas fa-phone-alt"/>
                                    <h4>phone</h4>
                                </div>
                                <div className="contact_content">
                                    <ul>
                                        <li>-</li>
                                        <li>-</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <div className="contact_wrap">
                                <div className="title_bar">
                                    <i className="fas fa-fax"/>
                                    <h4>fax</h4>
                                </div>
                                <div className="contact_content">
                                    <ul>
                                        <li>-</li>
                                        <li>-</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="small-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="get-in-touch">
                                <h3>get in touch</h3>
                                <form>
                                    <div className="row">
                                        <div className="form-group col-md-6">
                                            <input type="text" className="form-control" id="name"
                                                   placeholder="first name"
                                                   required=""/>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <input type="text" className="form-control" id="last-name"
                                                   placeholder="last name"
                                                   required=""/>
                                        </div>
                                        <div className="form-group col-lg-6">
                                            <input type="text" className="form-control" id="review"
                                                   placeholder="phone number"
                                                   required=""/>
                                        </div>
                                        <div className="form-group col-lg-6">
                                            <input type="text" className="form-control" id="email"
                                                   placeholder="email address"
                                                   required=""/>
                                        </div>
                                        <div className="form-group col-md-12">
                                    <textarea className="form-control" placeholder="Write Your Message"
                                              id="exampleFormControlTextarea1" rows="6"></textarea>
                                        </div>
                                        <div className="col-md-12 submit-btn">
                                            <button className="btn btn-solid" type="submit">Send Your Message</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="contact-map">
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d115796.02722078805!2d66.98358794921364!3d24.91074085304011!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3eb33ff627168fa1%3A0xf29b0864e9c83b50!2sChaloje%20Travel%20%26%20Tourism!5e0!3m2!1sen!2s!4v1652961141596!5m2!1sen!2s"
                                    allowFullScreen/>
                            </div>
                            </div>
                        </div>
                    </div>
            </section>
        </Fragment>
    )
}

export default ContactPage
