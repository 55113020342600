import { useEffect } from "react";

const useScript = (url, callback) => {
  useEffect(() => {
    const script = document.createElement("script");

    script.src = `./assets/js/custom/${url}`;
    script.async = true;

    if (callback) script.onload = callback;

    document.body.append(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [url]);
};

export default useScript;
