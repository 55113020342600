import {
    ADD_SELECTED_FLIGHT,
    CLEAR_SELECTED_FLIGHT,
    CLEAR_SELECTED_FLIGHTS,
    RESET_FLIGHT_DATA,
    SAVE_BOOKING_ID,
    SAVE_PNR,
    SET_TRAVELERS
} from "redux/actionTypes";

const INITIAL_STATE = {
    travelers: null,
    pnr: null,
    booking_id: null,
    selectedFlights: {}
}

export const flightReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_TRAVELERS:
            return {...state, travelers: action.payload};
        case SAVE_PNR:
            return {...state, pnr: action.payload}
        case ADD_SELECTED_FLIGHT:
            return {...state, selectedFlights: {...state.selectedFlights, ...action.payload}}
        case CLEAR_SELECTED_FLIGHT:
            const {[action.payload]: _, ...rest} = state.selectedFlights;
            return {...state, selectedFlights: rest};
        case CLEAR_SELECTED_FLIGHTS:
            return {...state, selectedFlights: {}}
        case SAVE_BOOKING_ID:
            return {...state, booking_id: action.payload}
        case RESET_FLIGHT_DATA:
            return {...state, pnr: null, booking_id: null, selectedFlights: {}}
        default:
            return state;

    }
}
