// Protected Route Component Code
import React from 'react'
import {Navigate, useLocation} from "react-router-dom";
import {useSelector} from "react-redux";

const ProtectedRoute = ({children}) => {
    const {user} = useSelector(store => store.user);
    const {pathname} = useLocation();

    return user ? children : <Navigate to='/sign-in' state={{from: pathname}}/>

}

export default ProtectedRoute;
