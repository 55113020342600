import React, {Fragment, useEffect, useState} from 'react'
import $ from "jquery";

import 'styles/ManageBooking.css'
import {getAirlineImage} from "utils";
import {useMutation} from "react-query";
import apis from "services";

const ManageBookingPage = () => {
    useEffect(() => {
        $('.bg-img').each(function () {

            const el = $(this),
                src = el.attr('src'),
                parent = el.parent();


            parent.css({
                'background-image': 'url(' + src + ')',
                'background-size': 'cover',
                'background-position': 'center',
                'background-repeat': 'no-repeat',
                'display': 'block'
            });

            el.hide();
        });
    }, []);

    const [data, setData] = useState(null);
    const [formData, setFormData] = useState({email: '', orderId: ''});
    const [error, setError] = useState('');

    const handleSearchPNR = async (e) => {
        e.preventDefault();
        mutate(formData);
    };

    const onChangeHandler = (e) => {
        const {name, value} = e.target;
        setFormData(currFormData => ({...currFormData, [name]: value}))
    }

    const {mutate, isLoading} = useMutation('findTicket', apis.findTicket, {
        onError: (error) => setError(error.response.data.error),
        onSuccess: ({data}) => {
            if (data.Success === false) {
                setError(data.Response.message);
            } else
                setData(data);
        }
    })

    const _data = {
        "outbound": {
            "flightInfo": {
                "flno": "PF123",
                "orig": "KHI",
                "dest": "ISB",
                "dpdate": "07-Jun-2022",
                "dpDateTime": "01:00 PM",
                "arrivalTime": "03:00 PM",
                "departureTime": "01:00 PM",
                "class": "1010",
                "status": "HK"
            },
            "fareInfo": {
                "adult": {
                    "fare": 4320,
                    "surcharge": 0,
                    "fee": "45",
                    "tax": 2100,
                    "total": 6465
                },
                "child": {
                    "fare": 3500,
                    "surcharge": 0,
                    "fee": "45",
                    "tax": 2100,
                    "total": 5645
                },
                "infant": {
                    "fare": "0",
                    "surcharge": 0,
                    "fee": "45",
                    "tax": 1500,
                    "total": 1545
                }
            },
            "fareType": {
                "SUB_CLASS_ID": "2",
                "SUB_CLASS_DESC": "Standard Baggage",
                "ABBR": "SB",
                "NO_OF_BAGS": "1",
                "AMOUNT": "0",
                "ADV_TAX": "0",
                "ACTUAL_AMOUNT": "0"
            }
        },
        "loc_data": {
            "staff_code": "22118",
            "phone": "03332687771",
            "lcode": "11137221",
            "sname": "CHALOJE TRAVEL KHI",
            "resDate": "07-Jun-2022 02:10 PM",
            "uname": "chalojeapi",
            "timeLimit": "07-Jun-2022 02:21 PM",
            "resDtShort": "07-Jun-2022",
            "address": "KARACHI, PAKISTAN ",
            "agent_type": "59",
            "paymentModeType": "1"
        },
        "pnrNames": {
            "adult": [
                {
                    "title": "MRS",
                    "name": "KEVIN CURRY",
                    "cell": "923137559954",
                    "nic": "43202-1951939-3",
                    "gender": "F",
                    "pax_type": 1,
                    "dob": "04-NOV-19",
                    "tkt_no": "",
                    "booking_status": "HK",
                    "leg": {
                        "KHI-ISB": {
                            "STATUS": "HK",
                            "TITLE": "Confirm"
                        }
                    }
                }
            ]
        },
        "currency": "PKR",
        "pnrDetail": {
            "PNRN": "T473QE",
            "PNRN_STATUS": "Y",
            "RECEIVED_FROM": "85.187.128.31",
            "RESERVE_DT": "07-JUN-22",
            "SEAT": "1",
            "TIME_LMT": "07-JUN-22",
            "STAFF_CODE": "22118",
            "DELIVERY_OPTION": "C",
            "MOBILE_NO": "923137559954",
            "EMAIL_ADDRESS": "rexeqij@mailinator.com",
            "discount": null
        },
        "isTicketed": "0",
        "isDisplayFare": false,
        "discount": {
            "discount_code": "-1",
            "discount_name": "NO DISCOUNT"
        },
        "paxContactInfo": {
            "FONE": "923137559954",
            "REMARKS": "Aliquid voluptas id",
            "CELL_NO": "923137559954",
            "EMAIL_ADDRESS": "rexeqij@mailinator.com"
        },
        "inter": "D",
        "cities": {
            "KHI": "Karachi",
            "ISB": "Islamabad",
            "LHE": "Lahore",
            "PEW": "Peshawar",
            "SKT": "Sialkot",
            "UET": "QUETTA"
        },
        "summary": {
            "fare": "4,320",
            "surcharge": "0",
            "fees": "45",
            "taxes": "2,100",
            "total": "6,465"
        },
        "isReturn": false,
        "passengerCount": {
            "totalAdult": 1,
            "totalChild": 0,
            "totalInfant": 0
        },
        "refundPolicy": [
            {
                "POLICY": "More than 48 hours before flight departure",
                "REFUND_CHARGES": {
                    "nil": "PKR 0",
                    "standard": "PKR 1,500",
                    "extra": "PKR 0"
                },
                "REISSUE_CHARGES": {
                    "nil": "PKR 0",
                    "standard": "PKR 1,500",
                    "extra": "PKR 0"
                }
            },
            {
                "POLICY": "Within 48 hours to 24 hours before flight departure",
                "REFUND_CHARGES": {
                    "nil": "PKR 0",
                    "standard": "PKR 2,500",
                    "extra": "PKR 0"
                },
                "REISSUE_CHARGES": {
                    "nil": "PKR 0",
                    "standard": "PKR 2,500",
                    "extra": "PKR 0"
                }
            },
            {
                "POLICY": "Within 24 hours to before flight departure",
                "REISSUE_CHARGES": {
                    "nil": "PKR 0",
                    "standard": "PKR 3,500",
                    "extra": "PKR 0"
                },
                "REFUND_CHARGES": {
                    "nil": "PKR 0",
                    "standard": "PKR 3,500",
                    "extra": "PKR 0"
                }
            },
            {
                "POLICY": "After flight departure and/or no show",
                "REISSUE_CHARGES": {
                    "nil": "PKR 0",
                    "standard": "PKR 4,500",
                    "extra": "PKR 0"
                },
                "REFUND_CHARGES": {
                    "nil": "PKR 0",
                    "standard": "PKR 4,500",
                    "extra": "PKR 0"
                }
            },
            {
                "POLICY": "<b>29 Days</b> after flight departure",
                "REFUND_CHARGES": "No Refund",
                "REISSUE_CHARGES": "No Reissue"
            }
        ],
        "baggagePolicy": {
            "leg1": {
                "ORGN": "KHI",
                "DEST": "ISB",
                "JOURNEY": "181848",
                "CLASS_CODE": "1010",
                "DP_DATE": "07-JUN-22",
                "LEG": "1",
                "FLNO": "PF123",
                "PNRN": "T473QE",
                "SEAT": "1",
                "SEAT_STATUS": "HK",
                "FARE_TYPE_CODE": "2",
                "RESERVE_DT": "07-06-2022",
                "SUB_CLASS_DETAIL": {
                    "SUB_CLASS_ID": "2",
                    "SUB_CLASS_DESC": "Standard Baggage",
                    "ABBR": "SB",
                    "NO_OF_BAGS": "1",
                    "ADV_TAX": "0",
                    "AMOUNT": "0",
                    "ACTUAL_AMOUNT": "0"
                },
                "EXTRA_BAGGAGE_LIMIT": 0,
                "TICKET_BAGGAGE_STRING": "Karachi - Islamabad : 1 Piece(s) ( 0KG each)"
            },
            "RESERVE_DATE": "07-06-2022"
        }
    }

    console.log(_data);

    const price = _data['summary']['total'];
    const flightDetails = _data['baggagePolicy']['leg1'];
    const cities = _data['cities'];
    const passengersCount = _data['passengerCount'];
    const totalPassenger = Object.values(passengersCount)?.reduce((a, p) => a + p, 0)

    return (
        <Fragment>
            {/*<Breadcrumb small/>*/}

            <section className="order-food-section  pt-0">
                <img
                    src="./assets/images/bg-flight.png"
                    className="bg-img img-fluid blur-up lazyload"
                    alt=""/>
                <div className="order-food">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 offset-lg-2">
                                {error && (
                                    <>
                                        <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                            {error}
                                            <button type="button" className="btn-close" aria-label="Close"
                                                    onClick={() => setError('')}/>
                                        </div>
                                    </>
                                )}
                                <div className="book-table section-b-space p-0 single-table">
                                    <h3>Enter your Email & Order id</h3>
                                    <div className="table-form">
                                        <form onSubmit={handleSearchPNR}>
                                            <div className="row w-100">
                                                <div className="form-group col-md-4">
                                                    <input name='email'
                                                           type="email"
                                                           placeholder="enter your email"
                                                           className="form-control"
                                                           value={formData.email}
                                                           onChange={onChangeHandler}
                                                           required
                                                    />

                                                </div>
                                                <div className="form-group col-md-5">
                                                    <input type="text"
                                                           name='orderId'
                                                           placeholder="enter your order id"
                                                           className="form-control"
                                                           value={formData.orderId}
                                                           onChange={onChangeHandler}
                                                           required
                                                    />
                                                </div>
                                                <div className="search col-md-3">
                                                    <button type='submit'
                                                            disabled={isLoading}
                                                            className="btn btn-rounded color1">
                                                        {isLoading ? 'Searching' : 'Search'}
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {!data && (
                <section className='mb-4'>
                    <div id="content_wrapper">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="w-100 mx-auto">
                                        <div className="heading_tab_inner">
                                            <h5>FLIGHT DETAILS</h5>
                                            <span>change flight</span>
                                        </div>
                                        <div className="tab_inner_body" style={{float: 'left'}}>
                                            <div className="p-2">
                                                <div className="row">
                                                    <div className="col-lg-10 col-md-9 my-auto text-center">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4">
                                                                <img src={getAirlineImage('Airsial')}
                                                                     alt="airline image"/>
                                                            </div>
                                                            <div className="col-lg-8 col-md-8">
                                                                <h4> {cities[flightDetails['DEST']].toUpperCase()} </h4>
                                                                <span>{cities[flightDetails['ORGN']]} to {cities[flightDetails['DEST']]}</span>
                                                                <span> One Way Flight</span>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2 col-md-3 py-2">
                                                        <div className="rupees_left">
                                                            <h2> {price} Rupees</h2>
                                                            <p>Per Person</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="p-0 border-top-blue">
                                                <span
                                                    className="flight_no">Air Serene [Flight No: {flightDetails['FLNO']}]</span>
                                                <div className="main_section_flight">
                                                    <div className="country_section_box">
                                                        <p>Origin</p>
                                                        <p>{_data['outbound']['flightInfo']["orig"]}</p>
                                                        <h4> {_data['outbound']['flightInfo']["departureTime"]}</h4>
                                                        <p className="shedule_d">30 May 2022</p>
                                                    </div>
                                                    <div className="middle_flight_section">
                                                        <h5>Total Time</h5>
                                                        <p>
                                                            <i className="fa fa-clock-o"></i> 02 hours, 30
                                                            Minutes
                                                        </p>
                                                    </div>
                                                    <div className="country_section_box">
                                                        <p>Destination</p>
                                                        {/*<p></p>*/}
                                                        <p>{_data['outbound']['flightInfo']["dest"]}</p>
                                                        <h4>{_data['outbound']['flightInfo']["arrivalTime"]}</h4>
                                                        <p className="shedule_d">30 May 2022</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="border-top-blue">
                                                <div className="flight_details_table">
                                                    <div className="table_heding_title my-3">Flight Details</div>
                                                    <div className="hotel_airlines_list">
                                                        <div className="airline_detail_list">
                                                            <fieldset>
                                                                <ul>
                                                                    <li>
                                                                        <span className="styling-text">Airline</span>
                                                                        <span>-</span>
                                                                        <span>Air Serene</span>
                                                                    </li>
                                                                    <li>
                                                                        <span>Flight type</span>
                                                                        <span>-</span>
                                                                        <span>Economy</span>
                                                                    </li>
                                                                    <li>
                                                                        <span>Fare type</span>
                                                                        <span>-</span>
                                                                        <span>Refundable</span>
                                                                    </li>
                                                                    <li>
                                                                        <span>Cancellation</span>
                                                                        <span>-</span>
                                                                        <span>PKR 2250/Person</span>
                                                                    </li>
                                                                    <li>
                                                                        <span>Flight Change</span>
                                                                        <span>-</span>
                                                                        <span>PKR 2250/Person</span>
                                                                    </li>
                                                                </ul>
                                                            </fieldset>
                                                        </div>
                                                        <div className="airline_detail_list table_bold">
                                                            <fieldset>
                                                                <ul>
                                                                    <li>
                                                                        <span>Baggage</span>
                                                                        <span>-</span>
                                                                        <span>Extra charge</span>
                                                                    </li>
                                                                    <li>
                                                                        <span>In flight feature</span>
                                                                        <span>-</span>
                                                                        <span>Available</span>
                                                                    </li>
                                                                    <li>
                                                                        <span>Base fare</span>
                                                                        <span>-</span>
                                                                        <span className="bold">8000 PKR</span>
                                                                    </li>
                                                                    <li>
                                                                        <span>Tax &amp; Fees</span>
                                                                        <span>-</span>
                                                                        <span className="bold">3000 PKR</span>
                                                                    </li>
                                                                    <li>
                                                                        <span>Total Price</span>
                                                                        <span>-</span>
                                                                        <span className="bold">11000 PKR</span>
                                                                    </li>
                                                                </ul>
                                                            </fieldset>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="inludes_section">
                                                <div className="flight_details_table">
                                                    <div className="table_heding_title my-2">Traveller Details</div>
                                                    <div className="hotel_airlines_list">
                                                        <div className="airline_detail_list">
                                                            <fieldset>
                                                                <ul>
                                                                    <li>
                                                                        <span>Name</span>
                                                                        <span>-</span>
                                                                        <span>Mr Khizar</span>
                                                                    </li>
                                                                    <li>
                                                                        <span>Mobile No</span>
                                                                        <span>-</span>
                                                                        <span>+923137559954</span>
                                                                    </li>
                                                                    <li>
                                                                        <span>CNIC</span>
                                                                        <span>-</span>
                                                                        <span>42101-1951939-3</span>
                                                                    </li>
                                                                    <li>
                                                                        <span>Gender</span>
                                                                        <span>-</span>
                                                                        <span>Male</span>
                                                                    </li>
                                                                    <li>
                                                                        <span>PNR No</span>
                                                                        <span>-</span>
                                                                        <span>3BML1A</span>
                                                                    </li>
                                                                </ul>
                                                            </fieldset>
                                                        </div>
                                                        <div className="airline_detail_list table_bold">
                                                            <fieldset>
                                                                <ul>
                                                                    <li>
                                                                        <span>No. of Passsengers</span>
                                                                        <span>-</span>
                                                                        <span>{totalPassenger}</span>
                                                                    </li>
                                                                    <li>
                                                                        <span>Adult</span>
                                                                        <span>-</span>
                                                                        <span>{passengersCount['totalAdult']}</span>
                                                                    </li>
                                                                    <li>
                                                                        <span>Infant</span>
                                                                        <span>-</span>
                                                                        <span>{passengersCount['totalChild']}</span>
                                                                    </li>
                                                                    <li>
                                                                        <span>Child</span>
                                                                        <span>-</span>
                                                                        <span>{passengersCount['totalInfant']}</span>
                                                                    </li>
                                                                </ul>
                                                            </fieldset>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )}
        </Fragment>
    )
};

export default ManageBookingPage;
