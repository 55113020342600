export default function getParams(str) {
    let queryString = str || window.location.search || '';
    let keyValPairs = [];
    const params = {};
    queryString = queryString.replace(/.*?\?/, "");

    if (queryString.length) {
        keyValPairs = queryString.split('&');
        for (let pairNum in keyValPairs) {
            const key = keyValPairs[pairNum].split('=')[0];
            if (!key.length) continue;
            if (typeof params[key] === 'undefined')
                params[key] = '';
            params[key] = keyValPairs[pairNum].split('=')[1];
        }
    }
    return params;
}
