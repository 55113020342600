let windowObjectReference;
// let windowFeatures = "popup";
let windowFeatures = "";



function popupWindow(url) {
    windowObjectReference = window.open(url,windowFeatures);
    console.log('CHECKING', windowObjectReference)
}


export default popupWindow;
