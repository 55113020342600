import React, {Fragment} from 'react'
import {Link, useLocation} from "react-router-dom";
import {useScript} from "../../hooks";

const Breadcrumb = ({title, route = [], small}) => {
    useScript('breadcrumb.js')
    const {pathname} = useLocation();

    const getTitleFromRoute = () => pathname
        .substring(1)
        .split('-')
        .join(' ')
        .toLowerCase()
        .replace(/\b[a-z](?=[a-z]{2})/g, (x) => x.toUpperCase());

    const sectionClassName = small
        ? 'breadcrumb-section small-sec flight-sec pt-0'
        : 'breadcrumb-section pt-0 bg-size'

    const element = (<Fragment>
        {!small && <h2>{title ?? getTitleFromRoute()}</h2>}
        <nav aria-label="breadcrumb" className="theme-breadcrumb">
            <ol className="breadcrumb">
                <li className="breadcrumb-item">
                    <Link to='/'>
                        Home
                    </Link>
                </li>
                {route.length > 0
                    ? route.map(({label, url, active}, index) => {
                        return (<Fragment key={index}>
                            <li className={`breadcrumb-item ${active && 'active'}`}>
                                {
                                    active
                                        ? label
                                        : <Link to={url}>
                                            {label}
                                        </Link>
                                }

                            </li>
                        </Fragment>)
                    }) :
                    <li className="breadcrumb-item active" aria-current="page">
                        {getTitleFromRoute()}
                    </li>
                }
            </ol>
        </nav>
    </Fragment>)

    return (
        <section className={sectionClassName}>
            <img src="../assets/images/flights/flight-breadcrumb2.jpg" className="bg-img img-fluid blur-up" alt=""/>
            {small
                ? (
                    <div className="content-bottom">
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    {element}
                                </div>
                            </div>
                        </div>
                    </div>
                )
                : <div className="breadcrumb-content dark-content">
                    <div>
                        {element}
                    </div>
                </div>
            }

        </section>
    )
};

export default Breadcrumb;
