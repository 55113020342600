const calculateFlightBasePrice = (fare, travelers, baggageFare = 0) => {
    const result = Object.entries(travelers).reduce((calculatedPrice, traveler) => {
        const [key, quantity] = traveler;

        const travelerPrice = fare?.[key]?.["TOTAL"] || 0;

        calculatedPrice += travelerPrice * Number(quantity);

        return calculatedPrice
    }, 0);

    return result + Number(baggageFare);
}

export default calculateFlightBasePrice
