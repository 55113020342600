const currencies = [
    {
        code: 'PKR',
        symbol: 'RS.'
    }
]

const getCurrency = (currency) => {
    const country = currencies.find((_currency) => _currency.code === currency);

    if (country)
        return country.symbol;

    return 'RS.'
}

export default getCurrency
