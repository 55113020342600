const Alert = ({error, setError}) => {
    const closeAlert = () => setError({...error, status: false});

    if (error.status) {
        if (typeof (error.errors) === 'object') {
            return Object.values(error.errors).map((errorMsg, i) => {
                return (
                    <div className={`alert alert-${error.role} alert-dismissible fade show`} role="alert" key={i}>
                        {errorMsg}
                        <button type="button" className="btn-close" aria-label="Close" onClick={closeAlert}/>
                    </div>
                )
            })
        }
    }

    return null
}

export default Alert