import React from 'react'

const Panel = ({title = '', children, disable, icon = null}) => {

    return (
        <div className="review_box">
            <div className="title-top d-flex justify-content-between">
                <h5>{title}</h5>
                {icon &&
                    <i onClick={icon?.onClick}
                       role='button'
                       className={`fa fa-lg fa-${icon?.name}`}
                    />}
            </div>
            {disable
                ? null
                : <div className="flight_detail payment-gateway">
                    {children}
                </div>}
        </div>
    )
};

export default Panel;