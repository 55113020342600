import React, {useState} from 'react'
import {useMutation, useQuery} from "react-query";
import apis from "services";
import {getParams, popupWindow} from "utils";

const FlightBookingFailedPage = () => {

    const [state, setState] = useState({message: '', Order_Ref_Number: 0});
    useQuery([getParams().data], apis.failedBooking, {
        onSuccess: ({data}) => setState(data)
    });

    const {mutate: retryBooking, isLoading} = useMutation('retryBooking', apis.retryBooking, {
        onError: (error) => console.log(error),
        onSuccess: ({data}) => {
            if (data.startsWith('https://testpaymentapi.hbl.com')) {
                popupWindow(data);
            }
        }
    });

    const tryAgainBtnHandler = () => {
        retryBooking({
            retry: true,
            booking_id: state.Order_Ref_Number,
        })
    }

    return (
        <section className="bg-inner section-b-space success-section">
            <div className="container">
                <div className="row success-detail mt-0">
                    <div className="col">
                        <img src="../assets/images/icon/failure-icon.png" className="img-fluid blur-up lazyload"
                             alt=""/>
                        <h2>oops ! we are unable to book your flight</h2>
                        <p>
                            {
                                state.message
                                    ? state.message + " ," + `Your Order Number is ${state.Order_Ref_Number}`
                                    : 'looks like we encountered an error. please try again. if you continue to have issue, contact us on 03XXXXXXXXX.'
                            }
                        </p>
                        <button className="btn btn-solid color1"
                                onClick={tryAgainBtnHandler}
                                disabled={isLoading}
                        >
                            {isLoading ? 'Loading' : 'try again'}
                        </button>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default FlightBookingFailedPage
