import React, {Fragment, useState} from 'react'
import {useSelector} from "react-redux";
import {useMutation} from "react-query";
import {useLocation, useNavigate} from "react-router-dom";

import apis from "services";
import {useBookSeat} from "hooks";
import {Breadcrumb} from "components/sections";
import {formatDate, popupWindow} from "utils";
import {Accordion, FareSummary, HBLPayment, Panel, PassengerDetails, TravelerDetailForms} from "components";
import {keysIn, valuesIn, valuesInLength} from "../utils/objects";

const BookingPage = ({routes}) => {
    const {state} = useLocation();
    const navigation = useNavigate();
    const flight = useSelector(store => store)
    console.log('flight', flight)
    console.log('BookingPage.js: state', state)
    const {pnr, travelers, booking_id} = useSelector(store => store.flight)

    const AIRLINE = Object.values(state.flights)[0]['AIRLINE'];

    /*TODO:: use Memo here*/
    useBookSeat(state);

    const [steps, setSteps] = useState(0)
    const [travelersData, setTravelersData] = useState({
        ADULT: [],
        CHILD: [],
        INFANT: [],
    });

    const [paymentData, setPaymentData] = useState({
        name: '',
        surname: '',
        email: '',
        phone: '',
        state: '',
        postalCode: '',
        country: '',
        addressLine: '',
    });

    const [contactData, setContactData] = useState({
        email: '',
        mobile: '',
        cnic: '',
        comments: '',
        register: false,
        password: '',
        confirmPassword: ''
    });


    /*==========    APIs    ==========*/
    const {mutate: saveBookingDetails} = useMutation('bookingDetails', apis.bookingDetails, {
        onError: (error) => console.log(`Error occurred while save booking details: ${error}`),
        onSuccess: ({data}) => {
            console.log('**********', data)
        }
    })

    const {mutate: generateBokking} = useMutation('passenger_insertion', apis.bookFlight, {
        onError: error => console.log('Error Occurred While Passenger Insertion', error),
        onSuccess: (data) => console.log('Success Passenger Insertion', data)
    });

    const {mutate: _HBLPayment, isLoading} = useMutation('payment_details', apis.paymentDetails, {
        onError: error => console.log(`Error Occurred while saving payment details: ${error}`),
        onSuccess: ({data}) => {
            if (data.startsWith('https://testpaymentapi.hbl.com')) {
                popupWindow(data);
            }
        }
    });

    /*==========    Methods    ==========*/

    const isPasswordValid = () => contactData.password === contactData.confirmPassword;

    const handleChange = (event, key, field, index) => {
        const traveler = travelersData[key];
        traveler[index] = {...travelersData[key][index], [field]: event.target.value};
        setTravelersData({...travelersData, [key]: traveler});
    };

    const handleContactOnChange = (event) => {
        setContactData((currentData) => {
            /*Set State depend on event for phone input library*/
            const {name, value} = event.nativeEvent ? event.target : event;
            return {...currentData, [name]: value}
        })
    };

    /*const handleSubmit = (e) => {
        e.preventDefault();
        const updateFormData = [];

        /!*Changing Form Data According to Airsial*!/
        Object.keys(travelers).map((traveler) => {
            if (travelersData[traveler].length > 0) {
                const updatedData = travelersData[traveler].map((travelerData) => {
                    // eslint-disable-next-line
                    return {
                        ...travelerData,
                        ["FullName"]: `${travelerData["Firstname"]} ${travelerData["Lastname"]}`,
                        ["Dob"]: formatDate(new Date(travelerData["Dob"])),
                    };
                });
                const key = traveler.toLowerCase();
                updateFormData[key] = updatedData;
            }
            return null;
        });

        navigation("/payment", {
            state: {userData: {...updateFormData, ...contactData}, total: 330, summary: state.summary},
        });
    };*/


    const onChangeHandler = (e) => {
        const {id, value} = e.target;
        setPaymentData({...paymentData, [id]: value})
    }

    const doHBLPaymentHandler = async (event) => {
        event.preventDefault();
        _HBLPayment({...paymentData, payment_method: 'HBL', order_number: booking_id})
    }

    const handleConfirmBooking = async () => {
        /*TODO:: Handle Confirm Booking Logic*/

        const {AIRLINE = 'Airsial', TRAVELERS_XML, OriginDestination_XML} = state.flight;
        const updateFormData = [];
        console.log(travelersData);
        Object.keys(travelers).forEach(traveler => {
            if (travelersData[traveler].length > 0) {
                const tempData = travelersData[traveler].map((data) => ({
                        ...data,
                        ["FullName"]: `${data['Firstname']} ${data['Lastname']}`,
                        ["Dob"]: formatDate(new Date(data["Dob"])),
                    }
                ));
                const key = traveler.toLowerCase();
                updateFormData[key] = tempData;
            }
        })

        switch (AIRLINE) {
            case 'Airblue':

                await generateBokking({
                    AIRLINE,
                    TRAVELERS_XML,
                    TRAVELERS: travelers,
                    OriginDestination_XML,
                    TRAVELERS_INFORMATION: travelersData
                });
                break;
            case 'Airsial':
                const formattedData = {
                    PNR: pnr,
                    CNIC: '43202-1951949-1',
                    Comments: '',
                    ...updateFormData
                };

                console.log(formattedData, '****');

                await generateBokking({AIRLINE, ...formattedData});
                break;
            default:
                break;
        }
    }

    const handleSteps = (num) => {
        if (num) {
            setSteps((currState) => {
                if ((num === -1 && currState === 0) || currState === 2) return currState;
                return currState + num;
            })
        }
    };

    const bookingDetails = () => {
        const updateFormData = [];
        const numberOfFlights = valuesInLength(state.flights);
        const {register, password} = contactData;

        if (numberOfFlights === 1) {
            /*==========   One Way Trip Logic   ==========*/

            if (AIRLINE === 'Airsial') {
                keysIn(travelers).forEach(traveler => {
                    if (travelersData[traveler].length > 0) {
                        const tempData = travelersData[traveler].map((data) => ({
                                ...data,
                                ["FullName"]: `${data['Firstname']} ${data['Lastname']}`,
                                ["Dob"]: formatDate(new Date(data["Dob"])),
                            }
                        ));
                        const key = traveler.toLowerCase();
                        updateFormData[key] = tempData;
                    }
                })

                if (register) {
                    const {password, email, mobile} = contactData;
                    const {Firstname, Lastname} = travelersData['ADULT']?.[0] || '';
                    const fullName = `${Firstname} ${Lastname}`;

                    saveBookingDetails({
                        booking_id,
                        cnic: contactData.cnic,
                        email,
                        mobile,
                        comments: contactData.comments,
                        ...updateFormData,
                        user: {
                            fullName,
                            email,
                            password,
                            mobile
                        }
                    })
                } else {
                    saveBookingDetails({
                        booking_id,
                        cnic: contactData.cnic,
                        email: contactData.email,
                        mobile: contactData.mobile,
                        comments: contactData.comments,
                        ...updateFormData,
                    })
                }
            } else if (AIRLINE === 'Airblue') {
                const [outbound] = valuesIn(state.flights);

                const {TRAVELERS_XML, OriginDestination_XML} = outbound;

                keysIn(travelersData).forEach(traveler => {
                    if (travelersData[traveler].length > 0) {
                        const tempData = travelersData[traveler].map((data) => ({
                                ...data,
                                ["FullName"]: `${data['Firstname']} ${data['Lastname']}`,
                            }
                        ));
                        const key = traveler.toLowerCase();
                        updateFormData[key] = tempData;
                    }
                })

                if (register) {
                    //TODO:: Missing Registration One Way Airblue
                } else {
                    saveBookingDetails({
                        TRAVELERS_XML,
                        OriginDestination_XML,
                        ...updateFormData,
                        booking_id,
                        cnic: contactData.cnic,
                        email: contactData.email,
                        mobile: contactData.mobile,
                        comments: contactData.comments,
                    })
                }
            }


        } else if (numberOfFlights === 2) {
            /*==========   Round Trip Logic   ==========*/
            if (AIRLINE === 'Airsial') {

            } else if (AIRLINE === 'Airblue') {
                const updateFormData = [];

                const [inbound, outbound] = valuesIn(state.flights);

                const {TRAVELERS_XML: INBOUND_TRAVELERS_XML, OriginDestination_XML} = inbound;
                const {
                    TRAVELERS_XML: OUTBOUND_TRAVELERS_XML,
                    OriginDestination_XML: ReturnOriginDestination_XML
                } = outbound;

                const destinationXML = OriginDestination_XML + '\n' + ReturnOriginDestination_XML;
                const travelersXML = INBOUND_TRAVELERS_XML + '\n' + OUTBOUND_TRAVELERS_XML;

                keysIn(travelersData).forEach(traveler => {
                    if (travelersData[traveler].length > 0) {
                        const tempData = travelersData[traveler].map((data) => ({
                                ...data,
                                ["FullName"]: `${data['Firstname']} ${data['Lastname']}`,
                            }
                        ));
                        const key = traveler.toLowerCase();
                        updateFormData[key] = tempData;
                    }
                });

                if (register) {
                    //TODO:: Missing Registration Round Trip Airblue
                } else {
                    saveBookingDetails({
                        TRAVELERS_XML: travelersXML,
                        OriginDestination_XML: destinationXML,
                        ...updateFormData,
                        booking_id,
                        cnic: contactData.cnic,
                        email: contactData.email,
                        mobile: contactData.mobile,
                        comments: contactData.comments,
                    })
                }

            }

        } else if (numberOfFlights > 2) {
            /*==========   MultiCity Logic   ==========*/

        }

        handleSteps(1);
    }

    return (<Fragment>
        <Breadcrumb route={routes} small/>

        {/*Booking Section*/}

        <section className="small-section">
            <div className="container">
                {/*<div className="card mb-3">
                    <div className="card-header" id='timer'>
                        <div className="btn btn-link" data-bs-toggle="collapse"
                             data-bs-target='timer' aria-expanded="true" aria-controls="one">
                            <label htmlFor='steps'>

                            </label>
                        </div>
                    </div>
                </div>*/}

                <div className="row">
                    <div className="col-lg-8">
                        <div className="review-section">
                            <Panel title='traveller details' disable={steps !== 0}>
                                <form onSubmit={(e) => {
                                    e.preventDefault();
                                    if (isPasswordValid())
                                        handleSteps(1);
                                    else {
                                        alert('Password & Confirm Password must be same');
                                    }
                                }}>
                                    <TravelerDetailForms
                                        travelersData={travelersData}
                                        contactData={contactData}
                                        onChange={handleChange}
                                        handleContactOnChange={handleContactOnChange}
                                        Airline={AIRLINE}
                                    />
                                    <div className="my-3 text-end">
                                        <button type="submit"
                                                className="btn btn-solid color1">Continue
                                        </button>
                                    </div>
                                </form>
                            </Panel>
                            <Panel title='Booking Confirmation' disable={steps !== 1}
                                   icon={{name: 'edit', onClick: () => handleSteps(-1)}}>
                                <PassengerDetails passengerData={travelersData}/>
                                <div className="my-3 text-end">
                                    <button
                                        onClick={bookingDetails}
                                        className="btn btn-solid color1">Confirm
                                    </button>
                                </div>
                            </Panel>
                            <Panel title='Payment Option' disable={steps !== 2}>
                                <Accordion title='Pay with HBL' expanded>
                                    <HBLPayment
                                        onChangeHandler={onChangeHandler}
                                        doHBLPaymentHandler={doHBLPaymentHandler}
                                        paymentData={paymentData}
                                        isLoading={isLoading}
                                    />
                                </Accordion>
                            </Panel>
                        </div>
                    </div>
                    <div className="col-lg-4 res-margin">
                        <div className="sticky-cls-top">
                            <div className="review-section">
                                <Panel title='Booking Summery'>
                                    <FareSummary summary={state.summary} coupon/>
                                </Panel>
                            </div>
                            {/*<button className='btn btn-solid my-2'
                                    onClick={handleConfirmBooking}>Generate Ticket
                            </button>*/}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </Fragment>)
};

export default BookingPage;

BookingPage.defaultProps = {
    routes: [
        {label: 'Search', url: '/search-flights', active: false},
        {label: 'Flight Details', url: '/flight-details', active: false},
        {label: 'Booking', url: '/', active: true},
    ]
}
