import React, {Fragment} from "react";
import {Footer, Header, ProtectedRoute} from "components";
import {
    BookingPage,
    ComingSoonPage,
    ContactPage,
    DashboardPage,
    FlightBookingFailedPage,
    FlightBookingSuccessPage,
    FlightDetailsPage,
    FlightListingPage,
    HomePage,
    ManageBookingPage,
    NoPage,
    SignInPage,
    SignUpPage
} from "pages";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import useFlight from "./hooks/useFlight";

const App = () => {
    const flightsData = useFlight();
    return (
        <Fragment>
            <BrowserRouter>
                <Header/>
                <Routes>
                    <Route path="/" element={<HomePage flights={flightsData}/>}/>
                    <Route path="/booking" element={<BookingPage/>}/>
                    <Route path="/hotels" element={<ComingSoonPage/>}/>
                    <Route path="/packages" element={<ComingSoonPage/>}/>
                    <Route path="/sign-in" element={<SignInPage/>}/>
                    <Route path="/sign-up" element={<SignUpPage/>}/>
                    <Route path="/contact" element={<ContactPage/>}/>
                    <Route path="/dashboard" element={<ProtectedRoute><DashboardPage/></ProtectedRoute>}/>
                    <Route path="/search-flights" element={<FlightListingPage flights={flightsData}/>}/>
                    <Route path="/flight-details" element={<FlightDetailsPage/>}/>
                    <Route path="/manage-booking" element={<ManageBookingPage/>}/>
                    <Route path="/flight-booking-success" element={<FlightBookingSuccessPage/>}/>
                    <Route path="/flight-booking-fail" element={<FlightBookingFailedPage/>}/>
                    <Route path="/*" element={<NoPage/>}/>
                </Routes>
                <Footer/>
            </BrowserRouter>
        </Fragment>
    )
};

export default App;
