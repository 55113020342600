import React, {Fragment, useEffect} from 'react'
import $ from 'jquery'

const FlightFilters = ({getLocationValue,flightData}) => {
    /*useEffect(()=>{
        $(document).ready( function() {
            var jQuery221 = $.noConflict(true);

            console.log(jQuery221);

            $('.grid').isotope({
                itemSelector: '.grid-item',
            });

            // filter items on button click
            $('.filter-button-group').on( 'click', 'li', function() {
                var filterValue = $(this).attr('data-filter');
                $('.grid').isotope({ filter: filterValue });
                $('.filter-button-group li').removeClass('active');
                $(this).addClass('active');
            });
        });
    },[]);*/
    return(
        <Fragment>
            <div className="flight-search">
                <div className="responsive-detail">
                    <div className="destination">
                        <span>{getLocationValue(flightData.locationDep)}</span>
                        <span>Karachi</span>
                        <span><i className="fas fa-long-arrow-alt-right mx-2"/></span>
                        <span>{getLocationValue(flightData.locationArr)}</span>
                        <span>Islamabad</span>
                    </div>
                    <div className="details">
                        <span>tue, 19-Aug-2019</span>
                        <span className="divider">|</span>
                        {/*<span>{getTravellersValue(flightData.travelers)}</span>*/}
                    </div>
                    <div className="modify-search">
                        <a href="/#" className="btn btn-solid color1"> modify search</a>
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="top-filter-section">
                    <ul>
                        <li className="back-btn">
                            <div className="mobile_back text-end">
                                <span>Back</span>
                                <i aria-hidden="true" className="fa fa-angle-right ps-2"/>
                            </div>
                        </li>
                        <li className="filter-title">
                            <h6>filter :</h6>
                        </li>
                        <li className="onclick-title">
                            <h6>stops</h6>
                            <div className="onclick-content">
                                <div className="form-check collection-filter-checkbox">
                                    <input type="checkbox" className="form-check-input" id="free-d"/>
                                        <label className="form-check-label" htmlFor="free-d">non stop</label>
                                </div>
                                <div className="form-check collection-filter-checkbox">
                                    <input type="checkbox" className="form-check-input" id="time"/>
                                        <label className="form-check-label" htmlFor="time">1 stop</label>
                                </div>
                                <div className="form-check collection-filter-checkbox">
                                    <input type="checkbox" className="form-check-input" id="zara"/>
                                        <label className="form-check-label" htmlFor="zara">2 stop</label>
                                </div>
                            </div>
                        </li>
                        <li className="onclick-title">
                            <h6>fare type</h6>
                            <div className="onclick-content">
                                <div className="form-check collection-filter-checkbox">
                                    <input type="checkbox" className="form-check-input" id="refundable"/>
                                        <label className="form-check-label" htmlFor="refundable">refundable</label>
                                </div>
                                <div className="form-check collection-filter-checkbox">
                                    <input type="checkbox" className="form-check-input" id="non"/>
                                        <label className="form-check-label" htmlFor="non">non-refundable</label>
                                </div>
                            </div>
                        </li>
                        <li className="onclick-title">
                            <h6>price</h6>
                            <div className="onclick-content">
                                <div className="wrapper">
                                    <div className="range-slider">
                                        <input type="text" className="js-range-slider" value=""/>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="onclick-title">
                            <h6>airlines</h6>
                            <div className="onclick-content">
                                <div className="collection-brand-filter">
                                    <div className="form-check collection-filter-checkbox">
                                        <input type="checkbox" className="form-check-input" id="wifi"/>
                                            <label className="form-check-label" htmlFor="wifi">Qatar airways</label>
                                    </div>
                                    <div className="form-check collection-filter-checkbox">
                                        <input type="checkbox" className="form-check-input" id="spa"/>
                                            <label className="form-check-label" htmlFor="spa">singapore airlines</label>
                                    </div>
                                    <div className="form-check collection-filter-checkbox">
                                        <input type="checkbox" className="form-check-input" id="pet"/>
                                            <label className="form-check-label" htmlFor="pet">Nippon Airways</label>
                                    </div>
                                    <div className="form-check collection-filter-checkbox">
                                        <input type="checkbox" className="form-check-input" id="parking"/>
                                            <label className="form-check-label" htmlFor="parking">Cathay Pacific</label>
                                    </div>
                                    <div className="form-check collection-filter-checkbox">
                                        <input type="checkbox" className="form-check-input" id="swimming"/>
                                            <label className="form-check-label" htmlFor="swimming">Emirates</label>
                                    </div>
                                    <div className="form-check collection-filter-checkbox">
                                        <input type="checkbox" className="form-check-input" id="chinese"/>
                                            <label className="form-check-label" htmlFor="chinese">Hainan
                                                Airlines</label>
                                    </div>
                                    <div className="form-check collection-filter-checkbox">
                                        <input type="checkbox" className="form-check-input" id="restaurant"/>
                                            <label className="form-check-label" htmlFor="restaurant">Qantas
                                                Airways</label>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="onclick-title">
                            <h6>departure</h6>
                            <div className="onclick-content">
                                <div className="form-check collection-filter-checkbox">
                                    <input type="checkbox" className="form-check-input" id="suomi"/>
                                        <label className="form-check-label" htmlFor="suomi">
                                            <img
                                            src="../assets/images/icon/time/sunrise.png"
                                            className="img-fluid blur-up lazyload me-1" alt=""/> 6am to 12pm</label>
                                </div>
                                <div className="form-check collection-filter-checkbox">
                                    <input type="checkbox" className="form-check-input" id="english"/>
                                        <label className="form-check-label" htmlFor="english">
                                            <img
                                            src="../assets/images/icon/time/sun.png"
                                            className="img-fluid blur-up lazyload me-1" alt=""/>
                                            12pm to 6pm</label>
                                </div>
                                <div className="form-check collection-filter-checkbox">
                                    <input type="checkbox" className="form-check-input" id="sign"/>
                                        <label className="form-check-label" htmlFor="sign"><img
                                            src="../assets/images/icon/time/night.png"
                                            className="img-fluid blur-up lazyload me-1" alt=""/> after 6pm</label>
                                </div>
                            </div>
                        </li>
                        <li className="onclick-title">
                            <h6>arrival</h6>
                            <div className="onclick-content">
                                <div className="form-check collection-filter-checkbox">
                                    <input type="checkbox" className="form-check-input" id="morning"/>
                                        <label className="form-check-label" htmlFor="morning"><img
                                            src="../assets/images/icon/time/sunrise.png"
                                            className="img-fluid blur-up lazyload me-1" alt=""/> 6am to 12pm</label>
                                </div>
                                <div className="form-check collection-filter-checkbox">
                                    <input type="checkbox" className="form-check-input" id="noon"/>
                                        <label className="form-check-label" htmlFor="noon"><img
                                            src="../assets/images/icon/time/sun.png"
                                            className="img-fluid blur-up lazyload me-1" alt=""/>
                                            12pm to 6pm</label>
                                </div>
                                <div className="form-check collection-filter-checkbox">
                                    <input type="checkbox" className="form-check-input" id="evening"/>
                                        <label className="form-check-label" htmlFor="evening"><img
                                            src="../assets/images/icon/time/night.png"
                                            className="img-fluid blur-up lazyload me-1" alt=""/> after 6pm</label>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="col-lg-12 ratio3_2">
                <a href="/#" className="mobile-filter border-top-0">
                    <h5>latest filter</h5>
                    <img src="../assets/images/icon/adjust.png" className="img-fluid blur-up lazyload"
                         alt=""/>
                </a>
            </div>
        </Fragment>
    )
}

export default FlightFilters
