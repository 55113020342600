import React, {Fragment, useState} from 'react'
import {useSelector} from "react-redux";
import {useScript} from "hooks";
import {useMutation} from "react-query";
import apis from "../services";

const DashboardPage = () => {
    useScript('script.js')
    const {user} = useSelector(store => store.user);

    const [userData, setUserData] = useState(user);

    const onChangeHandler = (e) => {
        const {id, value} = e.target;
        setUserData(prevState => ({...prevState, [id]: value}));
    }

    const {mutate, isLoading} = useMutation('updateProfile', apis.updateProfile, {
        onError: (err) => console.log(`Error occurred while updating profile ${err}`),
        onSuccess: ({data}) => {
            console.log(data);
        }
    });

    const submitHandler = (e) => {
        e.preventDefault();
        mutate(userData);
    }


    return (
        <Fragment>
            <section className="small-section dashboard-section bg-inner" data-sticky_parent>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="pro_sticky_info" data-sticky_column>
                                <div className="dashboard-sidebar">
                                    <div className="profile-top">
                                        <div className="profile-image">
                                            <img
                                                src={user.photoURL || "../assets/images/avtar/1.jpg"}
                                                className="img-fluid blur-up lazyload" alt=""/>
                                            <a className="profile-edit" href="#">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                     viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                     strokeWidth="2"
                                                     strokeLinecap="round" strokeLinejoin="round"
                                                     className="feather feather-edit">
                                                    <path
                                                        d="M20 14.66V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h5.34">
                                                    </path>
                                                    <polygon points="18 2 22 6 12 16 8 16 8 12 18 2"/>
                                                </svg>
                                            </a>
                                        </div>
                                        <div className="profile-detail">
                                            <h5>{user?.full_name}</h5>
                                            <h6>{user?.phone}</h6>
                                            <h6>{user?.email}</h6>
                                        </div>
                                    </div>
                                    <div className="faq-tab">
                                        <ul className="nav nav-tabs" id="top-tab" role="tablist">
                                            <li className="nav-item"><a data-bs-toggle="tab" className="nav-link active"
                                                                        href="#dashboard">dashboard</a></li>
                                            <li className="nav-item"><a data-bs-toggle="tab" className="nav-link"
                                                                        href="#profile">profile</a></li>
                                            <li className="nav-item"><a data-bs-toggle="tab" className="nav-link"
                                                                        href="#bookings">bookings</a></li>
                                            <li className="nav-item"><a data-bs-toggle="tab" className="nav-link"
                                                                        href="#added-card">cards
                                                & payment</a></li>
                                            {/*<li className="nav-item"><a data-bs-toggle="tab" className="nav-link"
                                                                        href="#bookmark">bookmark</a></li>*/}
                                            {/*<li className="nav-item"><a data-bs-toggle="tab" className="nav-link"
                                                                        href="#security">security</a></li>*/}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-9">
                            <div className="product_img_scroll" data-sticky_column>
                                <div className="faq-content tab-content" id="top-tabContent">
                                    <div className="tab-pane fade show active" id="dashboard">
                                        <div className="dashboard-main">
                                            <div className="dashboard-intro">
                                                <h5>welcome! <span>{user?.full_name}</span></h5>
                                                <p>you have completed 70% of your profile. add basic info to complete
                                                    profile.
                                                </p>
                                                {/*<div className="complete-profile">
                                                    <div className="row">
                                                        <div className="col-xl-4">
                                                            <div className="complete-box">
                                                                <i className="far fa-check-square"></i>
                                                                <h6>verified email ID</h6>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-4">
                                                            <div className="complete-box">
                                                                <i className="far fa-check-square"></i>
                                                                <h6>verified phone number</h6>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-4">
                                                            <div className="complete-box not-complete">
                                                                <i className="far fa-window-close"></i>
                                                                <h6>complete basic info</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>*/}
                                            </div>
                                            <div className="counter-section">
                                                <div className="row">
                                                    <div className="col-xl-3 col-sm-6">
                                                        <div className="counter-box">
                                                            <img src="../assets/images/icon/hotel.png"
                                                                 className="img-fluid blur-up lazyload" alt=""/>
                                                            <h3>0</h3>
                                                            <h5>hotel booked</h5>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-3 col-sm-6">
                                                        <div className="counter-box">
                                                            <img src="../assets/images/icon/flight.png"
                                                                 className="img-fluid blur-up lazyload" alt=""/>
                                                            <h3>1</h3>
                                                            <h5>flight booked</h5>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-3 col-sm-6">
                                                        <div className="counter-box">
                                                            <img src="../assets/images/icon/taxi.png"
                                                                 className="img-fluid blur-up lazyload" alt=""/>
                                                            <h3>0</h3>
                                                            <h5>cab booked</h5>
                                                        </div>
                                                    </div>
                                                    {/*<div className="col-xl-3 col-sm-6">
                                                        <div className="counter-box">
                                                            <img src="../assets/images/icon/food.png"
                                                                 className="img-fluid blur-up lazyload" alt=""/>
                                                                <h3>0</h3>
                                                                <h5>food ordered</h5>
                                                        </div>
                                                    </div>*/}
                                                </div>
                                            </div>
                                            <div className="dashboard-info">
                                                <div className="row">
                                                    {/*<div className="col-md-6">
                                                        <div id="chart">
                                                            <div className="detail-left">
                                                                <ul>
                                                                    <li><span className="completed"></span> Completed
                                                                    </li>
                                                                    <li><span className="upcoming"></span> Upcoming</li>
                                                                    <li><span className="cancelled"></span> Cancelled
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>*/}
                                                    <div className="col-md-6">
                                                        <div className="activity-box">
                                                            <h6>recent activity</h6>
                                                            <ul>
                                                                <li>
                                                                    <i className="fas fa-plane"></i>
                                                                    Karachi to Islamabad
                                                                    <span>3 days ago</span>
                                                                </li>
                                                                {/* <li>
                                                                    <i className="fas fa-plane"></i>
                                                                    Paris to Dubai
                                                                    <span>23 june</span>
                                                                </li>
                                                                <li className="blue-line">
                                                                    <i className="fas fa-hotel"></i>
                                                                    hotel sea view
                                                                    <span>20 april</span>
                                                                </li>
                                                                <li className="yellow-line">
                                                                    <i className="fas fa-taxi"></i>
                                                                    Paris To Toulouse
                                                                    <span>12 feb</span>
                                                                </li>
                                                                <li>
                                                                    <i className="fas fa-plane"></i>
                                                                    Paris to Dubai
                                                                    <span>14 jan</span>
                                                                </li>
                                                                <li className="blue-line">
                                                                    <i className="fas fa-hotel"></i>
                                                                    hotel sea view
                                                                    <span>12 jan</span>
                                                                </li>*/}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="profile">
                                        <div className="dashboard-box">
                                            <div className="dashboard-title">
                                                <h4>profile</h4>
                                                <span data-bs-toggle="modal" data-bs-target="#edit-profile">edit</span>
                                            </div>
                                            <div className="dashboard-detail">
                                                <ul>
                                                    <li>
                                                        <div className="details">
                                                            <div className="left">
                                                                <h6>name</h6>
                                                            </div>
                                                            <div className="right">
                                                                <h6>{user?.first_name}</h6>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="details">
                                                            <div className="left">
                                                                <h6>birthday</h6>
                                                            </div>
                                                            <div className="right">
                                                                <h6>{user?.dob || 'nill'}</h6>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="details">
                                                            <div className="left">
                                                                <h6>gender</h6>
                                                            </div>
                                                            <div className="right">
                                                                <h6>{user?.gender || 'NIll'}</h6>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="details">
                                                            <div className="left">
                                                                <h6>address</h6>
                                                            </div>
                                                            <div className="right">
                                                                <h6>{user?.address || 'Nill'}</h6>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="details">
                                                            <div className="left">
                                                                <h6>city/state</h6>
                                                            </div>
                                                            <div className="right">
                                                                <h6>{user?.state || 'Nill'}</h6>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="details">
                                                            <div className="left">
                                                                <h6>zip</h6>
                                                            </div>
                                                            <div className="right">
                                                                <h6>{user?.zip_code || 'Nill'}</h6>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="dashboard-box">
                                            <div className="dashboard-title">
                                                <h4>login details</h4>
                                            </div>
                                            <div className="dashboard-detail">
                                                <ul>
                                                    <li>
                                                        <div className="details">
                                                            <div className="left">
                                                                <h6>email address</h6>
                                                            </div>
                                                            <div className="right">
                                                                <h6>mark.enderess@mail.com</h6>
                                                                <span data-bs-toggle="modal"
                                                                      data-bs-target="#edit-address">edit</span>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="details">
                                                            <div className="left">
                                                                <h6>phone no:</h6>
                                                            </div>
                                                            <div className="right">
                                                                <h6>+91 123 - 456 - 7890</h6>
                                                                <span data-bs-toggle="modal"
                                                                      data-bs-target="#edit-phone">edit</span>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="details">
                                                            <div className="left">
                                                                <h6>password</h6>
                                                            </div>
                                                            <div className="right">
                                                                <h6>&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;</h6>
                                                                <span data-bs-toggle="modal"
                                                                      data-bs-target="#edit-password">edit</span>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="bookings">
                                        {/* <div className="dashboard-box">
                                            <div className="dashboard-title">
                                                <h4>upcoming booking</h4>
                                            </div>
                                            <div className="dashboard-detail">
                                                <div className="booking-box">
                                                    <div className="date-box">
                                                        <span className="day">tue</span>
                                                        <span className="date">14</span>
                                                        <span className="month">aug</span>
                                                    </div>
                                                    <div className="detail-middle">
                                                        <div className="media">
                                                            <div className="icon">
                                                                <i className="fas fa-plane"></i>
                                                            </div>
                                                            <div className="media-body">
                                                                <h6 className="media-heading">dubai to paris</h6>
                                                                <p>amount paid: <span>$2500</span></p>
                                                            </div>
                                                            <div className="media-body">
                                                                <h6 className="media-heading">ID: aSdsadf5s1f5</h6>
                                                                <p>order date: <span>20 oct, 2020</span></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="detail-last">
                                                        <a href="#"><i className="fas fa-window-close"
                                                                       data-bs-toggle="tooltip"
                                                                       data-placement="top" title="cancle booking"></i></a>
                                                        <span className="badge bg-info">upcoming</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="dashboard-detail">
                                                <div className="booking-box">
                                                    <div className="date-box">
                                                        <span className="day">tue</span>
                                                        <span className="date">14</span>
                                                        <span className="month">aug</span>
                                                    </div>
                                                    <div className="detail-middle">
                                                        <div className="media">
                                                            <div className="icon">
                                                                <i className="fas fa-hotel"></i>
                                                            </div>
                                                            <div className="media-body">
                                                                <h6 className="media-heading">sea view hotel</h6>
                                                                <p>amount paid: <span>$2500</span></p>
                                                            </div>
                                                            <div className="media-body">
                                                                <h6 className="media-heading">ID: aSdsadf5s1f5</h6>
                                                                <p>order date: <span>20 oct, 2020</span></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="detail-last">
                                                        <a href="#"><i className="fas fa-window-close"
                                                                       data-bs-toggle="tooltip"
                                                                       data-placement="top" title="cancle booking"></i></a>
                                                        <span className="badge bg-info">upcoming</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="dashboard-detail">
                                                <div className="booking-box">
                                                    <div className="date-box">
                                                        <span className="day">tue</span>
                                                        <span className="date">14</span>
                                                        <span className="month">aug</span>
                                                    </div>
                                                    <div className="detail-middle">
                                                        <div className="media">
                                                            <div className="icon">
                                                                <i className="fas fa-taxi"></i>
                                                            </div>
                                                            <div className="media-body">
                                                                <h6 className="media-heading">paris to Toulouse</h6>
                                                                <p>amount paid: <span>$2500</span></p>
                                                            </div>
                                                            <div className="media-body">
                                                                <h6 className="media-heading">ID: aSdsadf5s1f5</h6>
                                                                <p>order date: <span>20 oct, 2020</span></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="detail-last">
                                                        <a href="#"><i className="fas fa-window-close"
                                                                       data-bs-toggle="tooltip"
                                                                       data-placement="top" title="cancle booking"></i></a>
                                                        <span className="badge bg-info">upcoming</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>*/}
                                        <div className="dashboard-box">
                                            <div className="dashboard-title">
                                                <h4>past booking</h4>
                                            </div>
                                            <div className="dashboard-detail">
                                                <div className="booking-box">
                                                    <div className="date-box">
                                                        <span className="day">wed</span>
                                                        <span className="date">18</span>
                                                        <span className="month">may</span>
                                                    </div>
                                                    <div className="detail-middle">
                                                        <div className="media">
                                                            <div className="icon">
                                                                <i className="fas fa-plane"></i>
                                                            </div>
                                                            <div className="media-body">
                                                                <h6 className="media-heading">karachi to islamabad</h6>
                                                                <p>amount paid: <span>Rs. 8500</span></p>
                                                            </div>
                                                            <div className="media-body">
                                                                <h6 className="media-heading">ID: aSdsadf5s1f5</h6>
                                                                <p>order date: <span>18 may, 2022</span></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="detail-last">
                                                        <span className="badge bg-success">past</span>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="dashboard-detail">
                                                <div className="booking-box">
                                                    <div className="date-box">
                                                        <span className="day">tue</span>
                                                        <span className="date">14</span>
                                                        <span className="month">aug</span>
                                                    </div>
                                                    <div className="detail-middle">
                                                        <div className="media">
                                                            <div className="icon">
                                                                <i className="fas fa-hotel"></i>
                                                            </div>
                                                            <div className="media-body">
                                                                <h6 className="media-heading">sea view hotel</h6>
                                                                <p>amount paid: <span>$2500</span></p>
                                                            </div>
                                                            <div className="media-body">
                                                                <h6 className="media-heading">ID: aSdsadf5s1f5</h6>
                                                                <p>order date: <span>20 oct, 2020</span></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="detail-last">
                                                        <span className="badge bg-success">past</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="dashboard-detail">
                                                <div className="booking-box">
                                                    <div className="date-box">
                                                        <span className="day">tue</span>
                                                        <span className="date">14</span>
                                                        <span className="month">aug</span>
                                                    </div>
                                                    <div className="detail-middle">
                                                        <div className="media">
                                                            <div className="icon">
                                                                <i className="fas fa-taxi"></i>
                                                            </div>
                                                            <div className="media-body">
                                                                <h6 className="media-heading">paris to Toulouse</h6>
                                                                <p>amount paid: <span>$2500</span></p>
                                                            </div>
                                                            <div className="media-body">
                                                                <h6 className="media-heading">ID: aSdsadf5s1f5</h6>
                                                                <p>order date: <span>20 oct, 2020</span></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="detail-last">
                                                        <span className="badge bg-success">past</span>
                                                    </div>
                                                </div>
                                            </div>*/}
                                        </div>
                                        {/*<div className="dashboard-box">
                                            <div className="dashboard-title">
                                                <h4>cancelled booking</h4>
                                            </div>
                                            <div className="dashboard-detail">
                                                <div className="booking-box">
                                                    <div className="date-box">
                                                        <span className="day">tue</span>
                                                        <span className="date">14</span>
                                                        <span className="month">aug</span>
                                                    </div>
                                                    <div className="detail-middle">
                                                        <div className="media">
                                                            <div className="icon">
                                                                <i className="fas fa-plane"></i>
                                                            </div>
                                                            <div className="media-body">
                                                                <h6 className="media-heading">dubai to paris</h6>
                                                                <p>amount paid: <span>$2500</span></p>
                                                            </div>
                                                            <div className="media-body">
                                                                <h6 className="media-heading">ID: aSdsadf5s1f5</h6>
                                                                <p>order date: <span>20 oct, 2020</span></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="detail-last">
                                                        <span className="badge bg-secondary">cancelled</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="dashboard-detail">
                                                <div className="booking-box">
                                                    <div className="date-box">
                                                        <span className="day">tue</span>
                                                        <span className="date">14</span>
                                                        <span className="month">aug</span>
                                                    </div>
                                                    <div className="detail-middle">
                                                        <div className="media">
                                                            <div className="icon">
                                                                <i className="fas fa-hotel"></i>
                                                            </div>
                                                            <div className="media-body">
                                                                <h6 className="media-heading">sea view hotel</h6>
                                                                <p>amount paid: <span>$2500</span></p>
                                                            </div>
                                                            <div className="media-body">
                                                                <h6 className="media-heading">ID: aSdsadf5s1f5</h6>
                                                                <p>order date: <span>20 oct, 2020</span></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="detail-last">
                                                        <span className="badge bg-secondary">cancelled</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="dashboard-detail">
                                                <div className="booking-box">
                                                    <div className="date-box">
                                                        <span className="day">tue</span>
                                                        <span className="date">14</span>
                                                        <span className="month">aug</span>
                                                    </div>
                                                    <div className="detail-middle">
                                                        <div className="media">
                                                            <div className="icon">
                                                                <i className="fas fa-taxi"></i>
                                                            </div>
                                                            <div className="media-body">
                                                                <h6 className="media-heading">paris to Toulouse</h6>
                                                                <p>amount paid: <span>$2500</span></p>
                                                            </div>
                                                            <div className="media-body">
                                                                <h6 className="media-heading">ID: aSdsadf5s1f5</h6>
                                                                <p>order date: <span>20 oct, 2020</span></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="detail-last">
                                                        <span className="badge bg-secondary">cancelled</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>*/}
                                    </div>
                                    <div className="tab-pane fade" id="added-card">
                                        <div className="dashboard-box">
                                            <div className="dashboard-title">
                                                <h4>cards & payment</h4>
                                            </div>
                                            <div className="dashboard-detail">
                                                <div className="row card-payment">
                                                    {/*<div className="col-xl-4 col-sm-6">
                                                        <div className="payment-card master">
                                                            <div className="card-details">
                                                                <div className="card-number">
                                                                    <h3>XXXX-XXXX-XXXX-2510</h3>
                                                                </div>
                                                                <div className="valid-detail">
                                                                    <div className="title">
                                                                        <span>valid</span>
                                                                        <span>thru</span>
                                                                    </div>
                                                                    <div className="date">
                                                                        <h3>12/23</h3>
                                                                    </div>
                                                                    <div className="primary">
                                                                <span
                                                                    className="badge bg-pill badge-light">primary</span>
                                                                    </div>
                                                                </div>
                                                                <div className="name-detail">
                                                                    <div className="name">
                                                                        <h5>mark jecno</h5>
                                                                    </div>
                                                                    <div className="card-img">
                                                                        <img src="../assets/images/icon/master.png"
                                                                             className="img-fluid blur-up lazyload"
                                                                             alt=""/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="edit-card">
                                                                <a data-bs-toggle="modal" data-bs-target="#edit-card"
                                                                   href="#"><i
                                                                    className="far fa-edit"></i> edit</a>
                                                                <a href="#"><i
                                                                    className="far fa-minus-square"></i> delete</a>
                                                            </div>
                                                        </div>
                                                        <div className="edit-card-mobile">
                                                            <a data-bs-toggle="modal" data-bs-target="#edit-card"
                                                               href="#"><i
                                                                className="far fa-edit"></i> edit</a>
                                                            <a href="javascript:void(0)"><i
                                                                className="far fa-minus-square"></i>
                                                                delete</a>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-4 col-sm-6">
                                                        <div className="payment-card visa">
                                                            <div className="card-details">
                                                                <div className="card-number">
                                                                    <h3>XXXX-XXXX-XXXX-2510</h3>
                                                                </div>
                                                                <div className="valid-detail">
                                                                    <div className="title">
                                                                        <span>valid</span>
                                                                        <span>thru</span>
                                                                    </div>
                                                                    <div className="date">
                                                                        <h3>12/23</h3>
                                                                    </div>
                                                                </div>
                                                                <div className="name-detail">
                                                                    <div className="name">
                                                                        <h5>mark jecno</h5>
                                                                    </div>
                                                                    <div className="card-img">
                                                                        <img src="../assets/images/icon/visa.png"
                                                                             className="img-fluid blur-up lazyload"
                                                                             alt=""/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="edit-card">
                                                                <a data-bs-toggle="modal" data-bs-target="#edit-card"
                                                                   href="#"><i
                                                                    className="far fa-edit"></i> edit</a>
                                                                <a href="#"><i
                                                                    className="far fa-minus-square"></i> delete</a>
                                                            </div>
                                                        </div>
                                                        <div className="edit-card-mobile">
                                                            <a data-bs-toggle="modal" data-bs-target="#edit-card"
                                                               href="#"><i
                                                                className="far fa-edit"></i> edit</a>
                                                            <a href="javascript:void(0)"><i
                                                                className="far fa-minus-square"></i>
                                                                delete</a>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-4 col-sm-6">
                                                        <div className="payment-card american-express">
                                                            <div className="card-details">
                                                                <div className="card-number">
                                                                    <h3>XXXX-XXXX-XXXX-2510</h3>
                                                                </div>
                                                                <div className="valid-detail">
                                                                    <div className="title">
                                                                        <span>valid</span>
                                                                        <span>thru</span>
                                                                    </div>
                                                                    <div className="date">
                                                                        <h3>12/23</h3>
                                                                    </div>
                                                                </div>
                                                                <div className="name-detail">
                                                                    <div className="name">
                                                                        <h5>mark jecno</h5>
                                                                    </div>
                                                                    <div className="card-img">
                                                                        <img src="../assets/images/icon/american.jpg"
                                                                             className="img-fluid blur-up lazyload"
                                                                             alt=""/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="edit-card">
                                                                <a data-bs-toggle="modal" data-bs-target="#edit-card"
                                                                   href="#"><i
                                                                    className="far fa-edit"></i> edit</a>
                                                                <a href="#"><i
                                                                    className="far fa-minus-square"></i> delete</a>
                                                            </div>
                                                        </div>
                                                        <div className="edit-card-mobile">
                                                            <a data-bs-toggle="modal" data-bs-target="#edit-card"
                                                               href="#"><i
                                                                className="far fa-edit"></i> edit</a>
                                                            <a href="javascript:void(0)"><i
                                                                className="far fa-minus-square"></i>
                                                                delete</a>
                                                        </div>
                                                    </div>*/}
                                                    <div className="col-xl-4 col-sm-6">
                                                        <div className="payment-card add-card">
                                                            <div data-bs-toggle="modal" data-bs-target="#add-card"
                                                                 className="card-details">
                                                                <div>
                                                                    <i className="fas fa-plus"></i>
                                                                    <h5>add new card</h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="bookmark">
                                        <div className="dashboard-box">
                                            <div className="dashboard-title">
                                                <h4>my bookmark</h4>
                                            </div>
                                            <div className="product-wrapper-grid ratio3_2 special-section grid-box">
                                                <div className="row content grid">
                                                    <div className="col-xl-4 col-sm-6 grid-item">
                                                        <div className="special-box">
                                                            <div className="special-img">
                                                                <a href="#">
                                                                    <img src="../assets/images/tour/tour/7.jpg"
                                                                         className="img-fluid blur-up lazyload bg-img"
                                                                         alt=""/>
                                                                </a>
                                                                <div className="content_inner">
                                                                    <a href="#">
                                                                        <h5>Beautiful bali</h5>
                                                                    </a>
                                                                    <h6>6N 7D</h6>
                                                                </div>
                                                                <div className="top-icon">
                                                                    <a href="#" className="" data-bs-toggle="tooltip"
                                                                       data-placement="top"
                                                                       title="Remove from Wishlist"><i
                                                                        className="fas fa-times"></i></a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-4 col-sm-6 grid-item">
                                                        <div className="special-box">
                                                            <div className="special-img">
                                                                <a href="#">
                                                                    <img src="../assets/images/tour/tour/8.jpg"
                                                                         className="img-fluid blur-up lazyload bg-img"
                                                                         alt=""/>
                                                                </a>
                                                                <div className="content_inner">
                                                                    <a href="#">
                                                                        <h5>best of europe</h5>
                                                                    </a>
                                                                    <h6>6N 7D</h6>
                                                                </div>
                                                                <div className="top-icon">
                                                                    <a href="#" className="" data-bs-toggle="tooltip"
                                                                       data-placement="top"
                                                                       title="Remove from Wishlist"><i
                                                                        className="fas fa-times"></i></a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-4 col-sm-6 grid-item">
                                                        <div className="special-box">
                                                            <div className="special-img">
                                                                <a href="#">
                                                                    <img src="../assets/images/hotel/room/13.jpg"
                                                                         className="img-fluid blur-up lazyload bg-img"
                                                                         alt=""/>
                                                                </a>
                                                                <div className="content_inner">
                                                                    <a href="#">
                                                                        <h5>sea view hotel</h5>
                                                                    </a>
                                                                    <h6>$250/ night</h6>
                                                                </div>
                                                                <div className="top-icon">
                                                                    <a href="#" className="" data-bs-toggle="tooltip"
                                                                       data-placement="top"
                                                                       title="Remove from Wishlist"><i
                                                                        className="fas fa-times"></i></a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-4 col-sm-6 grid-item">
                                                        <div className="special-box">
                                                            <div className="special-img">
                                                                <a href="#">
                                                                    <img
                                                                        src="../assets/images/restaurant/environment/3.jpg"
                                                                        className="img-fluid blur-up lazyload bg-img"
                                                                        alt=""/>
                                                                </a>
                                                                <div className="content_inner">
                                                                    <a href="#">
                                                                        <h5>italian restro</h5>
                                                                    </a>
                                                                    <h6>fast food | $25 for two</h6>
                                                                </div>
                                                                <div className="top-icon">
                                                                    <a href="#" className="" data-bs-toggle="tooltip"
                                                                       data-placement="top"
                                                                       title="Remove from Wishlist"><i
                                                                        className="fas fa-times"></i></a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-4 col-sm-6 grid-item">
                                                        <div className="special-box">
                                                            <div className="special-img">
                                                                <a href="#">
                                                                    <img
                                                                        src="../assets/images/flights/flight-breadcrumb2.jpg"
                                                                        className="img-fluid blur-up lazyload bg-img"
                                                                        alt=""/>
                                                                </a>
                                                                <div className="content_inner">
                                                                    <a href="#">
                                                                        <h5>dubai to paris</h5>
                                                                    </a>
                                                                    <h6>egyptair | $2500</h6>
                                                                </div>
                                                                <div className="top-icon">
                                                                    <a href="#" className="" data-bs-toggle="tooltip"
                                                                       data-placement="top"
                                                                       title="Remove from Wishlist"><i
                                                                        className="fas fa-times"></i></a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-4 col-sm-6 grid-item">
                                                        <div className="special-box">
                                                            <div className="special-img">
                                                                <a href="#">
                                                                    <img src="../assets/images/tour/tour/12.jpg"
                                                                         className="img-fluid blur-up lazyload bg-img"
                                                                         alt=""/>
                                                                </a>
                                                                <div className="content_inner">
                                                                    <a href="#">
                                                                        <h5>simply mauritius</h5>
                                                                    </a>
                                                                    <h6>6N 7D</h6>
                                                                </div>
                                                                <div className="top-icon">
                                                                    <a href="#" className="" data-bs-toggle="tooltip"
                                                                       data-placement="top"
                                                                       title="Remove from Wishlist"><i
                                                                        className="fas fa-times"></i></a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-4 col-sm-6 grid-item">
                                                        <div className="special-box">
                                                            <div className="special-img">
                                                                <a href="#">
                                                                    <img src="../assets/images/tour/tour/13.jpg"
                                                                         className="img-fluid blur-up lazyload bg-img"
                                                                         alt=""/>
                                                                </a>
                                                                <div className="content_inner">
                                                                    <a href="#">
                                                                        <h5>canadian delight</h5>
                                                                    </a>
                                                                    <h6>6N 7D</h6>
                                                                </div>
                                                                <div className="top-icon">
                                                                    <a href="#" className="" data-bs-toggle="tooltip"
                                                                       data-placement="top"
                                                                       title="Remove from Wishlist"><i
                                                                        className="fas fa-times"></i></a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-4 col-sm-6 grid-item">
                                                        <div className="special-box">
                                                            <div className="special-img">
                                                                <a href="#">
                                                                    <img src="../assets/images/tour/tour/14.jpg"
                                                                         className="img-fluid blur-up lazyload bg-img"
                                                                         alt=""/>
                                                                </a>
                                                                <div className="content_inner">
                                                                    <a href="#">
                                                                        <h5>Egyptian Wonders</h5>
                                                                    </a>
                                                                    <h6>6N 7D</h6>
                                                                </div>
                                                                <div className="top-icon">
                                                                    <a href="#" className="" data-bs-toggle="tooltip"
                                                                       data-placement="top"
                                                                       title="Remove from Wishlist"><i
                                                                        className="fas fa-times"></i></a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-4 col-sm-6 grid-item">
                                                        <div className="special-box">
                                                            <div className="special-img">
                                                                <a href="#">
                                                                    <img src="../assets/images/tour/tour/15.jpg"
                                                                         className="img-fluid blur-up lazyload bg-img"
                                                                         alt=""/>
                                                                </a>
                                                                <div className="content_inner">
                                                                    <a href="#">
                                                                        <h5>South Africa</h5>
                                                                    </a>
                                                                    <h6>6N 7D</h6>
                                                                </div>
                                                                <div className="top-icon">
                                                                    <a href="#" className="" data-bs-toggle="tooltip"
                                                                       data-placement="top"
                                                                       title="Remove from Wishlist"><i
                                                                        className="fas fa-times"></i></a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="security">
                                        <div className="dashboard-box">
                                            <div className="dashboard-title">
                                                <h4>delete your accont</h4>
                                            </div>
                                            <div className="dashboard-detail">
                                                <div className="delete-section">
                                                    <p>Hi <span className="text-bold">Mark Enderess</span>,</p>
                                                    <p>we are sorry to here you would like to delete your account.</p>
                                                    <p><span className="text-bold">note:</span></p>
                                                    <p>deleting your account will permanently remove your profile,
                                                        personal
                                                        settings, and all other associated information.
                                                        once your account is deleted, you will be logged out and will be
                                                        unable
                                                        to log back in.
                                                    </p>
                                                    <p>if you understand and agree to the above statement, and would
                                                        still like
                                                        to delete your account, than click below</p>
                                                    <a href="#" data-bs-toggle="modal" data-bs-target="#delete-account"
                                                       className="btn btn-solid">delete my account</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*==========    edit profile modal start   ==========*/}
                <div className="modal fade edit-profile-modal" id="edit-profile" tabIndex="-1" role="dialog"
                     aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Edit Profile</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                            </div>
                            <form onSubmit={submitHandler}>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="form-group col-md-6 required">
                                            <label htmlFor="first_name">first name </label>
                                            <input type="text" className="form-control" id="first_name"
                                                   placeholder="first name" value={userData?.first_name}
                                                   onChange={onChangeHandler} required
                                            />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label htmlFor="last_name">last name</label>
                                            <input type="text" className="form-control" id="last_name"
                                                   placeholder="Last Name" onChange={onChangeHandler}
                                            />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label htmlFor="gender">gender</label>
                                            <select id="gender"
                                                    className="form-control"
                                                    value={userData.gender}
                                                    onChange={onChangeHandler}
                                            >
                                                <option value=''>Choose...</option>
                                                <option>Female</option>
                                                <option>Male</option>
                                            </select>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>Date of Birth</label>
                                            <input className="form-control"
                                                   placeholder="18 april"
                                                   id="datepicker"
                                                   type='date'
                                                   onChange={onChangeHandler}
                                            />
                                        </div>
                                        <div className="form-group col-12">
                                            <label htmlFor="address">Address</label>
                                            <input type="text" className="form-control" id="address"
                                                   placeholder="1234 Main St" onChange={onChangeHandler}
                                            />
                                        </div>
                                        <div className="form-group col-md-5">
                                            <label htmlFor="city">City</label>
                                            <input type="text" className="form-control" id="city"
                                                   onChange={onChangeHandler}
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label htmlFor="state">State</label>
                                            <input className='form-control' placeholder='State'/>
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label htmlFor="zip_code">Zip</label>
                                            <input type="text" className="form-control" id="zip_code"
                                                   onChange={onChangeHandler}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close
                                    </button>
                                    <button type="submit" className="btn btn-solid">
                                        {isLoading ? 'Saving' : 'Save changes'}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {/*==========    edit profile modal end   ==========*/}
            </section>
        </Fragment>
    )
}

export default DashboardPage
