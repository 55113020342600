import {useDispatch} from "react-redux";
import {useQuery} from "react-query";
import apis from "services";
import {get} from "lodash";
import {setTravelers} from "redux/actions/flight";

const useFetchFlights = (params) => {
    const {
        adultNo,
        childNo,
        infantNo,
        departingOn,
        locationDep,
        locationArr,
        returningOn,
        return: returning,
    } = params

    const dispatch = useDispatch();

    const {data, isLoading: fetchingFlights} = useQuery(
        ['getFlights', departingOn, locationDep, locationArr, returning, returningOn, adultNo, childNo, infantNo],
        apis.getSingleFlight,
        {
            onError: (error) => console.log('ERROR', error),
            onSuccess: () => {
                dispatch(setTravelers({ADULT: adultNo, CHILD: childNo, INFANT: infantNo}))
            }
        }
    )

    const baggageFare = get(data, 'data.availableFareTypes', []);
    const outbound = get(data, 'data.outbound', []);
    const inbound = get(data, 'data.inbound', []);
    const message = get(data, 'data.Response.message', '');

    return [
        fetchingFlights,
        baggageFare,
        outbound,
        inbound,
        message
    ]
};

export default useFetchFlights;