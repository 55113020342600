import React, {Fragment, useState} from 'react'
import {useScript} from "hooks";
import apis from "../services";
import {Link, useNavigate} from "react-router-dom";
import {useMutation} from "react-query";
import {useSelector} from "react-redux";
import {Alert} from "../components";
import PhoneInput from "react-phone-input-2";

const SignUpPage = () => {
    useScript('script.js');

    const [formState, setFormState] = useState({
        first_name: '',
        email: '',
        phone: '',
        password: '',
        confirmPassword: ''
    });
    const navigation = useNavigate()

    const {user} = useSelector(store => store.user);

    const [error, setError] = useState({status: false, errors: {message: []}, role: 'danger'})

    const {mutate: registerMutate, isLoading: loading} = useMutation('register', apis.registerApi, {
        onError: (error) => console.log(error),
        onSuccess: ({data}) => {
            if (data.status === false) {
                setError({role: 'danger', errors: data.errors, status: true});
            } else {
                setError({role: 'success', errors: {message: [data.message]}, status: true});
                setFormState({first_name: '', email: '', phone: '', password: '', confirmPassword: ''})
            }
        }
    })

    const handleOnChange = (e) => {
        const {id, value} = e.target;
        setFormState({...formState, [id]: value})
    }

    const passwordConfirmation = (password, confirmPassword) => password === confirmPassword;

    const handleRegister = (e) => {
        e.preventDefault();

        const isPasswordValid = passwordConfirmation(formState.password, formState.confirmPassword);

        if (isPasswordValid) {
            const {confirmPassword, ...restFields} = formState;
            registerMutate(restFields);
        } else {
            setError({
                ...error,
                errors: {
                    message: ['password and confirm password do not match']
                },
                status: true
            })
        }
    }

    if (user) return navigation('/');

    return (
        <Fragment>
            <section className="section-b-space animated-section dark-cls">
                <img src="../assets/images/cab/grey-bg.jpg" alt="" className="img-fluid blur-up lazyload bg-img"/>
                <div className="animation-section">
                    <div className="cross po-1"/>
                    <div className="cross po-2"/>
                    <div className="round po-4"/>
                    <div className="round po-5"/>
                    <div className="round r-y po-8"/>
                    <div className="square po-10"/>
                    <div className="square po-11"/>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="offset-lg-3 col-lg-6 offset-sm-2 col-sm-8 col-12">
                            <div className="account-sign-in">
                                <div className="title">
                                    <h3>sign up</h3>
                                </div>
                                <Alert error={error} setError={setError}/>
                                <form onSubmit={handleRegister}>
                                    <div className="form-group">
                                        <label htmlFor="first_name">Full name</label>
                                        <input type="text" className="form-control" id="first_name"
                                               placeholder="Enter your name" onChange={handleOnChange}
                                               value={formState.first_name} required
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="phone">Phone No</label>
                                        <PhoneInput
                                            id="phone"
                                            country={'pk'}
                                            value={formState.phone}
                                            onChange={(phone) => setFormState({...formState, phone})}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="email">Email address</label>
                                        <input type="email" className="form-control" id="email"
                                               placeholder="Enter email address" onChange={handleOnChange}
                                               value={formState.email} required
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="password">Password</label>
                                        <input type="password" className="form-control" id="password"
                                               placeholder="Password" onChange={handleOnChange}
                                               value={formState.password} required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="confirmPassword">Password</label>
                                        <input type="password" className="form-control" id="confirmPassword"
                                               placeholder="Confirm Password" onChange={handleOnChange}
                                               value={formState.confirmPassword} required
                                        />
                                    </div>
                                    <div className="button-bottom">
                                        <button disabled={loading} type="submit" className="w-100 btn btn-solid">
                                            {loading ? 'creating account' : 'create account'}
                                        </button>
                                        <div className="divider">
                                            <h6>or</h6>
                                        </div>
                                        <Link className="w-100 btn btn-solid btn-outline" to='/sign-in'>
                                            login
                                        </Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

export default SignUpPage
