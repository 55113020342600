const getAbbreviation = (IATA = '') => {
    const _IATA = IATA.toUpperCase();
    let output;
    switch (_IATA) {
        case "KHI":
            output = {airport: 'Jinnah International Airport', city: 'Karachi'}
            break;
        case 'ISB':
            output = {airport: 'Islamabad International Airport', city: 'Islamabad'}
            break;
        case 'LHE':
            output = {airport: 'Alama Iqbal International Airport', city: 'Lahore'}
            break;
        case 'PEW':
            output = {airport: 'Peshawar International Airport', city: 'Peshawar'}
            break;
        case 'SKT':
            output = {airport: 'Sialkot Airport', city: 'Sialkot'}
            break;
        case 'MUX':
            output = {airport: 'Multan International Airport', city: 'Multan'}
            break;
        case 'UET':
            output = {airport: 'Quetta International Airport', city: 'Quetta'}
            break;
        case 'LYP':
            output = {airport: 'Faisalabad International Airport', city: 'Faisalabad'}
            break;
        default:
            output = {airport: '', city: ''}
    }

    return output;
}

export default getAbbreviation