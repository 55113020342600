import {useState} from 'react'
import {useQuery} from "react-query";
import apis from "services";

const useFetchCities = (location) => {
    const [cities, setCities] = useState([])
    const param =  '';

    const {isLoading: fetchingCities} = useQuery(['cities', param], apis.getCities, {
        onError: err => console.log('ERROR: ', err),
        onSuccess: ({data}) => data && setCities(data)
    });

    return [
        fetchingCities,
        cities
    ]
};

export default useFetchCities;
