import React, {Fragment, useEffect, useMemo, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import calculateFlightBasePrice from "../utils/calculateFlightBasePrice";
import getCurrency from "../utils/getCurrency";
import {useNavigate} from "react-router-dom";
import getAirlineImage from "../utils/getAirlineImage";
import numberWithComma from "../utils/numberWithComma";
import {addFlight, clearFlight} from "../redux/actions/flight";

const FlightTicket = ({flight, searchParams, ticketType, selected}) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [isFlightSelected, setFlightSelected] = useState(false);

    const isRoundTrip = JSON.parse(searchParams.return);

    const {
        AIRLINE = 'Airsial',
        DEPARTURE_TIME,
        ORGN,
        DURATION,
        ARRIVAL_TIME,
        DEST,
        FLIGHT_NO,
        BAGGAGE_FARE,
        CURRENCY,
        STOPS,
    } = flight;
    const {travelers} = useSelector(store => store.flight)

    const price = useMemo(() => {
        if (BAGGAGE_FARE.hasOwnProperty('FARE_PAX_WISE'))
            return calculateFlightBasePrice(BAGGAGE_FARE["FARE_PAX_WISE"], travelers)
        else
            return calculateFlightBasePrice(BAGGAGE_FARE[0]["FARE_PAX_WISE"], travelers)
    }, [BAGGAGE_FARE, travelers])

    const currency = getCurrency(CURRENCY);

    const addFlightHandler = (flight) => dispatch(addFlight(ticketType, flight));

    const handleFlightSelected = () => {
        console.log('FlightTicket.js: select flight', flight);

        if (selected) {
            // dispatch(clearSelectedFlights());
            dispatch(clearFlight(flight.ticketType))
            return;
        }


        if (BAGGAGE_FARE.length > 1) {
            setFlightSelected(prevState => !prevState)
        } else {
            handleFlightSelect(flight, BAGGAGE_FARE);
        }
    };

    const handleFlightSelect = (_flight, baggage) => {
        const updatedFlight = {..._flight, BAGGAGE_FARE: baggage, AIRLINE: flight['AIRLINE'] || 'Airsial'};
        if (isRoundTrip)
            addFlightHandler({...updatedFlight, ticketType});
        else {
            navigate('/flight-details', {state: {flights: {outbound: updatedFlight}, searchParams}});
        }
    }


    return (
        <Fragment>
            <div className="detail-wrap wow fadeInUp overflow-hidden position-relative">
                <div className="row">
                    <div className='position-absolute' style={{ left: '-48px', paddingTop: '1em', top: '0'}}>
                        <h6 style={{ writingMode: 'tb', textTransform: 'uppercase'}}> {ticketType} </h6>
                    </div>
                    <div className="col-xl-3 col-md-2">
                        <div className="logo-sec">
                            <img src={getAirlineImage(AIRLINE)}
                                 className="img-fluid blur-up lazyload" alt=""/>
                            <span className="title">{AIRLINE}
                                <p className='text-muted'>{FLIGHT_NO}</p>
                        </span>
                        </div>
                    </div>
                    <div className="col-md-5">
                        <div className="airport-part">
                            <div className="airport-name">
                                <h4>{DEPARTURE_TIME}</h4>
                                <h6>{ORGN}</h6>
                            </div>
                            <div className="airport-progress">
                                <i className="fas fa-plane-departure float-start"/>
                                <i className="fas fa-plane-arrival float-end"/>
                                <div className="stop">
                                    {DURATION} ({STOPS ?? 0} stop)
                                </div>
                            </div>
                            <div className="airport-name arrival">
                                <h4>{ARRIVAL_TIME}</h4>
                                <h6>{DEST}</h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="price">
                            <div>
                                <h4>{currency}{numberWithComma(price)}</h4>
                                <span>non refundable</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-2 col-md-3">
                        <div className="book-flight">
                            <button
                                className="btn btn-solid color1"
                                style={isFlightSelected ? {backgroundColor: '#EE202B'} : {}}
                                onClick={handleFlightSelected}
                            >
                                {(() => {
                                    if (selected) {
                                        return 'Remove'
                                    } else {
                                        if (isFlightSelected)
                                            return 'Hide Details'
                                        else
                                            return 'book now'
                                    }
                                })()}
                            </button>
                        </div>
                    </div>
                </div>
                {isFlightSelected &&
                    <div className=" mt-2 w-100 row mx-0">
                        {isFlightSelected && BAGGAGE_FARE.map((baggageFare, index) => {
                                const flightPrice = calculateFlightBasePrice(baggageFare["FARE_PAX_WISE"], travelers, baggageFare["actual_amount"])

                                return (
                                    <div key={index} className="col-md-6 p-3 mt-4 border-odd">
                                        <div className='d-flex justify-content-between'>
                                            <div>
                                                <h6 style={{fontWeight: 'bold'}}>{baggageFare.sub_class_desc}</h6>
                                            </div>
                                            <div>
                                                <h4>
                                                    {currency}{numberWithComma(flightPrice)}
                                                </h4>
                                            </div>
                                        </div>
                                        <div className='d-flex justify-content-between'>
                                            <div className='d-flex align-items-baseline'>
                                                <i className='fa fa-shopping-bag bag fa-1x pe-2'
                                                   style={{color: '#4291b8'}}/>
                                                Baggage Included
                                            </div>
                                            <div><h6>{baggageFare.no_of_bags} Piece of 20 KG</h6></div>
                                        </div>
                                        <div className="pt-5 text-md-end">
                                            <button
                                                className='btn btn-solid color1 w-100'
                                                onClick={() => handleFlightSelect(flight, baggageFare)}
                                            >
                                                Select this fare
                                            </button>
                                        </div>
                                    </div>
                                )
                            }
                        )}
                    </div>
                }
            </div>
        </Fragment>
    )
}


export default FlightTicket
