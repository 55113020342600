import moment from "moment";

/*function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
}

function formatDate(date) {
    return [
        padTo2Digits(date.getDate()),
        padTo2Digits(date.getMonth() + 1),
        date.getFullYear(),
    ].join('-');
}*/

const formatDate = (date, format = 'MM-DD-YYYY') => {
    if (moment(date).isValid()) {
        return moment(date).format(format)
    } else {
        alert('Invalid')
    }
}

const validateDate = (_date, format = '') => {
    const [date, month, year] = _date.split('-');
    const validDate = `${month}-${date}-${year}`;
    return formatDate(validDate, format);
}

export {validateDate}
export default formatDate