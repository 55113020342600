import React, {Fragment, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Breadcrumb} from "components/sections";
import {useLocation, useNavigate} from "react-router-dom";
import {formatDate, getAbbreviation, getCurrency,} from "utils";
import {FareSummary, FlightTicketDetails, Panel} from "components";
import {validateDate} from "../utils/formatDate";
import {toPairsIn, valuesIn} from "../utils/objects";

const FlightDetailsPage = ({routes}) => {
    const {state} = useLocation();
    const navigation = useNavigate();

    const [summary, setSummary] = useState([]);
    const [fareSummary, setFareSummary] = useState([]);
    const [insurance, setInsurance] = useState(false);

    const {travelers} = useSelector((store) => store.flight);
    const {flights} = state;

    useEffect(() => {
        const list = {};
        const priceSummary = [];

        toPairsIn(flights).map(([flightType, flight], index) => {
            const fareSummary = {};

            const airline = flight?.['AIRLINE'] || "Airsial";
            const origin = flight['ORGN'];
            const destination = flight['DEST'];
            const fares = flight['BAGGAGE_FARE']['FARE_PAX_WISE'];
            const date = airline === 'Airsial'
                ? validateDate(flight['DEPARTURE_DATE'], 'Do-MMM-YY')
                : formatDate(flight['DEPARTURE_DATE'], 'Do-MMM-YY');


            const total = toPairsIn(travelers).reduce((acc, [title, no]) =>
                fares[title]
                    ? acc += fares[title]?.['TOTAL'] * no
                    : acc, 0);

            if (travelers['ADULT'] > 0) {
                fareSummary['Adult'] = {
                    ['type']: `${travelers['ADULT']} - ADT`,
                    ['fare']: fares['ADULT']['BASIC_FARE'],
                    ['taxFees']: Number(fares['ADULT']['FEES']) + Number(fares['ADULT']['SURCHARGE']) + Number(fares['ADULT']['TAX']),
                    ['total']: fares['ADULT']['TOTAL'] * travelers['ADULT']
                }
                priceSummary.push({label: 'ADULT', quantity: travelers['ADULT'], price: fares['ADULT']['TOTAL']});
            }

            if (travelers['CHILD'] > 0) {
                fareSummary['CHILD'] = {
                    ['type']: `${travelers['CHILD']} - CHD`,
                    ['fare']: fares['CHILD']['BASIC_FARE'],
                    ['taxFees']: Number(fares['CHILD']['FEES']) + Number(fares['CHILD']['SURCHARGE']) + Number(fares['CHILD']['TAX']),
                    ['total']: fares['CHILD']['TOTAL'] * travelers['CHILD']
                }
                priceSummary.push({label: 'CHILD', quantity: travelers['CHILD'], price: fares['CHILD']['TOTAL']});
            }


            if (travelers['INFANT'] > 0) {
                fareSummary['INFANT'] = {
                    ['type']: `${travelers['INFANT']} - INF`,
                    ['fare']: fares['INFANT']['BASIC_FARE'],
                    ['taxFees']: Number(fares['INFANT']['FEES']) + Number(fares['INFANT']['SURCHARGE']) + Number(fares['INFANT']['TAX']),
                    ['total']: fares['INFANT']['TOTAL'] * travelers['INFANT']
                }
                priceSummary.push({label: 'INFANT', quantity: travelers['INFANT'], price: fares['INFANT']['TOTAL']})
            }

            list[flightType] = {
                ['route']: `${getAbbreviation(origin).city} - ${getAbbreviation(destination).city}`,
                ['origin']: origin,
                ['destination']: destination,
                ['leaving']: flight['DEPARTURE_TIME'],
                ['landing']: flight['ARRIVAL_TIME'],
                ['aircraft']: flight['FLIGHT_NO'],
                ['date']: date,
                ['number']: '',
                ['fareSummary']: fareSummary,
                ['summary']: summary,
                ['total']: total
            }
        })

        setSummary(list);
        setFareSummary(priceSummary);
    }, [])

    /*Destructuring Values*/
    const cancellationCharges = valuesIn(flights)[0]?.RULES_INFO?.VoluntaryChanges?.Penalty || [];
    const refundsCharges = valuesIn(flights)[0]?.VoluntaryRefunds?.Penalty || [];

    const handleInsurance = (e) => {
        const {value} = e.target;

        console.log(e.target)

        const item = {label: "Insurance", price: 1000};

        if (value === "yes") {
            setSummary([...summary, item]);
        } else {
            setSummary(summary.filter(({label}) => label !== item.label));
        }
    };

    const faresInformation = (fee, index) => {
        const {_HoursBeforeDeparture, _CurrencyCode, _Amount} = fee;

        return <Fragment key={index}>
            <li>Airline Fee
                : {getCurrency(_CurrencyCode)} {_Amount} </li>
            {
                _HoursBeforeDeparture === '<0' && (
                    <li>After departure / No show.</li>
                )
            }
            {
                _HoursBeforeDeparture === '<48' && (
                    <li>Within 48 Hours before flight departure.</li>
                )
            }
            {
                _HoursBeforeDeparture === '>48' && (
                    <li>More than 48 Hours before flight departure.</li>
                )
            }
            <br/>
        </Fragment>
    }

    const handleContinueBooking = () => {
        navigation("/booking", {
            state: {fareSummary, summary, ...state}
        });
    }

    return (
        <Fragment>
            <Breadcrumb route={routes} small/>
            <section className="small-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="review-section">
                                <Panel title='flight details'>
                                    {
                                        valuesIn(flights).map((_flight, index) => (
                                            <FlightTicketDetails key={index} flight={_flight}/>
                                        ))
                                    }
                                </Panel>
                                <Panel title='Information'>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="boxes">
                                                <h6>Reschedule Charges</h6>
                                                <ul>
                                                    {cancellationCharges.map(faresInformation)}
                                                </ul>
                                            </div>
                                            <div className="boxes">
                                                <h6>Refunds Charges</h6>
                                                <ul>
                                                    {refundsCharges.map(faresInformation)}
                                                </ul>
                                            </div>
                                            <div className="boxes">
                                                <h6>baggage policy</h6>
                                                <ul>
                                                    <li>
                                                        Check-in Baggage : <span>15 kg</span>
                                                    </li>
                                                    <li>
                                                        Cabin Baggage: <span>7 kg</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </Panel>
                                {/*<Panel title='Travel Insurance'>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="boxes">
                                                <h6>
                                                    secure your travel with travel insurance for
                                                    RS. 1000
                                                </h6>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input radio_animated"
                                                        type="radio"
                                                        name="insurance"
                                                        id="exampleRadios1"
                                                        value={"yes"}
                                                        onChange={handleInsurance}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="exampleRadios1"
                                                    >
                                                        yes, i want to secure my travel with insurance
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input radio_animated"
                                                        type="radio"
                                                        name="insurance"
                                                        id="exampleRadios2"
                                                        value={"no"}
                                                        onChange={handleInsurance}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="exampleRadios2"
                                                    >
                                                        no, i do not want to secure my travel with
                                                        insurance
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Panel>*/}
                            </div>
                        </div>
                        <div className="col-lg-4 res-margin">
                            <div className="sticky-cls-top">
                                <div className="review-section">
                                    <Panel title='Booking Summery'>
                                        <FareSummary summary={summary}/>
                                    </Panel>
                                    <div className="review-section mt-4">
                                        <button className="btn btn-solid py-4 my-2 w-100"
                                                onClick={handleContinueBooking}
                                        >
                                            continue booking
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    );
};

export default FlightDetailsPage;

FlightDetailsPage.defaultProps = {
    routes: [
        {label: "Search", url: "/search-flights", active: false},
        {label: "Flight Details", url: "/", active: true},
    ]
}
