import React, {Fragment, memo, useEffect} from 'react'
import {useLocation, useNavigate} from "react-router-dom";

import $ from "jquery";

import {getParams} from "utils";
import {useFetchCities, useFetchFlights, useScript} from "hooks";
import {FlightFilters, FlightSearch, FlightsLoader, FlightTicket, FlightTrack} from "components";
import {Breadcrumb, NoFlightFound} from "components/sections";
import {useDispatch, useSelector} from "react-redux";
import {clearSelectedFlights, resetFlights} from "../redux/actions/flight";
import {valuesIn, valuesInLength} from "../utils/objects";

const FlightListingPage = memo(({flights}) => {

        useScript('datepicker.js');

        useEffect(() => {
            $(".open-select").click(function () {
                $(this).parent().find(".selector-box, .selector-box-flight").addClass("show");
            });


            $('.selector-box li, .bottom-part > button').click(function (e) {
                $(".selector-box, .selector-box-flight, .calender-external, .setting-open").removeClass("show")
            });
        })
        /*useEffect(()=>{
            dispatch(clearSelectedFlights())
        },[])*/

        const {search} = useLocation();
        const dispatch = useDispatch();
        const navigation = useNavigate();

        const params = getParams(search);

        const destination = params.locationDep;
        const arrival = params.locationArr;

        const isRoundTrip = params?.return && JSON.parse(params?.return);

        const [flightData, handleChange, getLocationValue, getTravellersValue, getURI] = flights;
        const [fetchingFlights, baggageFare, outbound, inbound, message] = useFetchFlights(params);

        const [fetchingCities, cities] = useFetchCities(flightData.locationDep);

        const {selectedFlights} = useSelector(store => store.flight);


        const handleTravelerMinusButton = (type) => {
            const {adultNo, childNo, infantNo} = flightData.travelers;
            const notAllowedStyle = {pointerEvents: 'none', backgroundColor: 'lightgray'};
            if (type === 'adult' && adultNo === 1)
                return notAllowedStyle;

            if (type === 'child' && childNo === 0)
                return notAllowedStyle;

            if (type === 'infant' && infantNo === 0)
                return notAllowedStyle;

            return {}
        }

        const handleTravelerPlusButton = (type) => {
            const {adultNo, infantNo, childNo} = flightData.travelers;
            const notAllowedStyle = {pointerEvents: 'none', backgroundColor: 'lightgray'};

            if (type === 'adult' && adultNo === 6)
                return notAllowedStyle;

            if (type === 'child' && childNo === 6)
                return notAllowedStyle;

            if (type === 'infant' && infantNo === 6)
                return notAllowedStyle;

            return {}
        }


        const handleSubmit = (e) => {
            e?.preventDefault();
            dispatch(clearSelectedFlights())
            localStorage.setItem('flight_details', JSON.stringify(flightData));
            navigation(getURI(), {replace: true})
        }

        const flightSearchParams = {
            handleSubmit,
            handleChange,
            flightData,
            getLocationValue,
            getTravellersValue,
            handleTravelerPlusButton,
            handleTravelerMinusButton,
            cities
        }

        const proceedTicketHandler = () => {
            navigation('/flight-details', {state: {flights: selectedFlights, baggage: [], searchParams: params}});
        }

        useEffect(() => {
            dispatch(resetFlights())
        }, [])

        return (
            <Fragment>
                <Breadcrumb/>

                {/*Filter*/}
                <div className="bg-inner small-section pb-0">
                    <div className="container">
                        <FlightSearch {...flightSearchParams}/>
                    </div>
                </div>


                <section className="pt-0 bg-inner small-section loader-section">
                    {fetchingFlights
                        ? <FlightsLoader/>
                        : <div className="container">
                            <div className="row">
                                <FlightFilters flightData={flightData} getLocationValue={getLocationValue}/>
                                <div className="col-lg-12 ratio3_2 my-2 pb-5">
                                    <div className="flight-detail-sec full_width-detail">

                                        {/*===========  SELECTED FLIGHT LOGIC START ===========*/}

                                        {(isRoundTrip && valuesInLength(selectedFlights) > 0) && (<Fragment>
                                            <h4 className='text-uppercase'> Selected Flights </h4>
                                            <div className="detail-bar">
                                                {
                                                    valuesInLength(selectedFlights) > 0 &&
                                                    valuesIn(selectedFlights).map((flight, index) => {
                                                        return <FlightTicket key={index}
                                                                             flight={flight}
                                                                             searchParams={params}
                                                                             selected/>

                                                    })
                                                }
                                            </div>
                                        </Fragment>)}

                                        {/*===========  SELECTED FLIGHT LOGIC END ===========*/}


                                        {/*===========  OUTBOUND LOGIC START ===========*/}
                                        {
                                            !selectedFlights.hasOwnProperty('outbound') &&
                                            (
                                                <Fragment>
                                                    {
                                                        outbound.length > 0 &&
                                                        <FlightTrack
                                                            to={arrival}
                                                            from={destination}
                                                            type='Outbound'
                                                        />
                                                    }
                                                    <div className="detail-bar">
                                                        {
                                                            (() => {
                                                                if (outbound.length > 0) {
                                                                    return outbound.map((flight, index) => (
                                                                        <FlightTicket key={index}
                                                                                      flight={flight}
                                                                                      ticketType="outbound"
                                                                                      searchParams={params}
                                                                        />))
                                                                } else {
                                                                    return <NoFlightFound/>
                                                                }
                                                            })()
                                                        }
                                                    </div>
                                                </Fragment>
                                            )
                                        }
                                        {/*===========  OUTBOUND LOGIC END ===========*/}

                                        {/*===========  INBOUND LOGIC START ===========*/}
                                        {
                                            !selectedFlights.hasOwnProperty('inbound') && isRoundTrip &&
                                            (<Fragment>
                                                {/*<FlightTrack
                                                    to={destination}
                                                    from={arrival}
                                                    type='inbound'
                                                />*/}
                                                <div className="detail-bar">
                                                    {
                                                        (() => {
                                                            if (inbound.length > 0) {
                                                                return inbound.map((flight, index) => (
                                                                    <FlightTicket key={index}
                                                                                  flight={flight}
                                                                                  ticketType="inbound"
                                                                                  searchParams={params}
                                                                    />))
                                                            } /*else {
                                                                return <NoFlightFound/>
                                                            }*/
                                                        })()
                                                    }
                                                </div>
                                            </Fragment>)
                                        }
                                        {/*===========  INBOUND LOGIC END ===========*/}
                                    </div>
                                    {
                                        valuesInLength(selectedFlights) === 2 && (
                                            <div className='d-flex justify-content-end'>
                                                <button
                                                    className="btn btn-solid color1"
                                                    onClick={proceedTicketHandler}
                                                >
                                                    Proceed
                                                </button>
                                            </div>
                                        )
                                    }

                                    {/*Pagination*/}
                                    {/*<nav aria-label="Page navigation example" className="pagination-section mt-0">
                                <ul className="pagination">
                                    <li className="page-item">
                                        <a className="page-link" href="/#" aria-label="Previous">
                                            <span aria-hidden="true">&laquo;</span>
                                            <span className="sr-only">Previous</span>
                                        </a>
                                    </li>
                                    <li className="page-item active"><a className="page-link"
                                                                        href="/#">1</a></li>
                                    <li className="page-item"><a className="page-link" href="/#">2</a>
                                    </li>
                                    <li className="page-item"><a className="page-link" href="/#">3</a>
                                    </li>
                                    <li className="page-item">
                                        <a className="page-link" href="#" aria-label="Next">
                                            <span aria-hidden="true">&raquo;</span>
                                            <span className="sr-only">Next</span>
                                        </a>
                                    </li>
                                </ul>
                            </nav>*/}
                                </div>
                            </div>
                        </div>
                    }
                </section>
            </Fragment>
        )
    }
)

export default FlightListingPage;
