import React, {Fragment} from 'react'
import {useScript} from "hooks";
import {Subscribe} from "components/sections";

const Footer = () => {
    useScript('script.js')
    return (
        <Fragment>
            <Subscribe/>
            <footer>
                <div className="footer section-b-space section-t-space">
                    <div className="container">
                        <div className="row order-row">
                            <div className="col-xl-2 col-md-6 order-cls">
                                <div className="footer-title mobile-title">
                                    <h5>contact us</h5>
                                </div>
                                <div className="footer-content">
                                    <div className="contact-detail">
                                        <div className="footer-logo">
                                            <img src="./assets/images/icon/chaloje.png" alt="chaloje"
                                                 className="img-fluid blur-up lazyload"/>
                                        </div>
                                        <p>Chaloje Travel & Tourism</p>
                                        <ul className="contact-list">
                                            <li><i className="fas fa-map-marker-alt"/> Office no. 204, plot no.66, 2nd
                                                floor, Iconic Trade Center, behind medicare hospital, Bihar Muslim
                                                Society Block 3 sharfabad, Karachi, Karachi City, Sindh.
                                            </li>
                                            <li><i className="fas fa-phone-alt"/> (021) 34855100</li>
                                            <li><i className="fas fa-envelope"/> info@chaloje.com</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-2 col-md-3">
                                <div className="footer-space">
                                    <div className="footer-title">
                                        <h5>about</h5>
                                    </div>
                                    <div className="footer-content">
                                        <div className="footer-links">
                                            <ul>
                                                <li><a href="/#">about us</a></li>
                                                <li><a href="/#">FAQ</a></li>
                                                <li><a href="/#">login</a></li>
                                                <li><a href="/#">register</a></li>
                                                <li><a href="/#">terms & co.</a></li>
                                                <li><a href="/#">privacy</a></li>
                                                <li><a href="/#">support</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6">
                                <div className="footer-title">
                                    <h5>our location</h5>
                                </div>
                                <div className="footer-content">
                                    <div className="footer-map">
                                        <iframe
                                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d115796.02722078805!2d66.98358794921364!3d24.91074085304011!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3eb33ff627168fa1%3A0xf29b0864e9c83b50!2sChaloje%20Travel%20%26%20Tourism!5e0!3m2!1sen!2s!4v1652961141596!5m2!1sen!2s"
                                            allowFullScreen/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-2 col-md-3 order-cls">
                                <div className="footer-space">
                                    <div className="footer-title">
                                        <h5>useful links</h5>
                                    </div>
                                    <div className="footer-content">
                                        <div className="footer-links">
                                            <ul>
                                                <li><a href="/#">home</a></li>
                                                <li><a href="/#">our vehical</a></li>
                                                <li><a href="/#">latest video</a></li>
                                                <li><a href="/#">services</a></li>
                                                <li><a href="/#">booking deal</a></li>
                                                <li><a href="/#">emergency call</a></li>
                                                <li><a href="/#">mobile app</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6">
                                <div className="footer-title">
                                    <h5>new topics</h5>
                                </div>
                                <div className="footer-content">
                                    <div className="footer-blog">
                                        <div className="media">
                                            <div className="img-part">
                                                <a href="/#"><img src="../assets/images/cab/blog-footer/1.jpg"
                                                                  className="img-fluid blur-up lazyload" alt=""/></a>
                                            </div>
                                            <div className="media-body">
                                                <h5>recent news</h5>
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting
                                                    industry.
                                                    Lorem Ipsum has been the industry's standard dummy text ever since
                                                    the
                                                    1500s, when an unknown printer took a galley of type and scrambled
                                                    it to
                                                    make a type specimen book. It has survived not only five
                                                    centuries</p>
                                            </div>
                                        </div>
                                        <div className="media">
                                            <div className="img-part">
                                                <a href="/#"><img src="../assets/images/cab/blog-footer/2.jpg"
                                                                  className="img-fluid blur-up lazyload" alt=""/></a>
                                            </div>
                                            <div className="media-body">
                                                <h5>recent news</h5>
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting
                                                    industry.
                                                    Lorem Ipsum has been the industry's standard dummy text ever since
                                                    the
                                                    1500s, when an unknown printer took a galley of type and scrambled
                                                    it to
                                                    make a type specimen book. It has survived not only five
                                                    centuries</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sub-footer">
                    <div className="container">
                        <div className="row ">
                            <div className="col-xl-6 col-md-6 col-sm-12">
                                <div className="footer-social">
                                    <ul>
                                        <li><a href="/#"><i className="fab fa-facebook-f"/></a></li>
                                        <li><a href="/#"><i className="fab fa-instagram"/></a></li>
                                        <li><a href="/#"><i className="fab fa-twitter"/></a></li>
                                        <li><a href="/#"><i className="fab fa-google"/></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-xl-6 col-md-6 col-sm-12">
                                <div className="copy-right">
                                    <p>© Chaloje {(new Date()).getFullYear()} All rights reserved. Powered
                                        by <i className="fas fa-heart"/> Petrol Solution.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            {/*<div className="theme-setting">
                <div className="dark">
                    <input className="tgl tgl-skewed" id="dark" type="checkbox"/>
                        <label className="tgl-btn" data-tg-off="Dark" data-tg-on="Light" htmlFor="dark"/>
                </div>
                <div className="rtl">
                    <input className="tgl tgl-skewed" id="rtl" type="checkbox"/>
                        <label className="tgl-btn" data-tg-off="RTL" data-tg-on="LTR" htmlFor="rtl"></label>
                </div>
            </div>*/}

            <div className="tap-top">
                <div>
                    <i className="fas fa-angle-up"/>
                </div>
            </div>
        </Fragment>
    )
};

export default Footer;
