import axios from "axios";
import constants from "utils/constants";

const createBackendServer = baseURL => {
    const api = axios.create({
        baseURL,
        headers: {
            'Accept': 'application/json',
        },
        timeout: 60 * 1000,
    });

    /*const setHeader = (key, value) => {
        // api.defaults.headers.post[key] = value;
        // api.defaults.headers.get[key] = value;
        // api.defaults.headers.put[key] = value;
        // api.defaults.headers.delete[key] = value;
        api.setHeader(key, value);
    };*/

    /*==========    GET REQUESTS    ==========*/

    const getCities = ({queryKey}) => api.get(`${queryKey[0]}`);
    const getSingleFlight = ({queryKey}) => api.get(`${queryKey[0]}?DepartingOn=${queryKey[1]}&LocationDep=${queryKey[2]}&LocationArr=${queryKey[3]}&Return=${queryKey[4]}&ReturningOn=${queryKey[5]}&AdultNo=${queryKey[6]}&ChildNo=${queryKey[7]}&InfantNo=${queryKey[8]}`)
    const bookSeat = ({queryKey}) => api.get(`${queryKey[0]}?Return=${queryKey[1]}&DepartureJourney=${queryKey[2]}&DepartureFareType=${queryKey[3]}&DepartureClass=${queryKey[4]}&DepartureFlight=${queryKey[5]}&ReturningJourney=${queryKey[6]}&ReturningClass=${queryKey[7]}&ReturningFlight=${queryKey[8]}&ReturningFareType=${queryKey[9]}&LocationDep=${queryKey[10]}&LocationArr=${queryKey[11]}&ftype=${queryKey[12]}&TotalSeats=${queryKey[13]}&totalInfant=${queryKey[14]}&totalAdult=${queryKey[15]}&totalChild=${queryKey[16]}`)
    const getAirport = ({queryKey}) => api.get(`${queryKey[0]}/${queryKey[1]},${queryKey[2]}`)
    const failedBooking = ({queryKey}) => api.get(`payment/status/failed?data=${queryKey}`);
    const successBooking = ({queryKey}) => api.get(`payment/status/success?data=${queryKey}`);
    const getViewTicket = ({queryKey}) => api.get(`viewTicket/${queryKey[0]}`);

    /*==========    POST REQUESTS    ==========*/

    const booking = body => api.post('/passengerInsertion', body, {headers: {"Content-Type": "application/json"}})
    const loginApi = body => api.post('member/login', body, {headers: {"Content-Type": "application/json"}})
    const registerApi = body => api.post('member/registration', body, {headers: {"Content-Type": "application/json"}})
    const updateProfile = body => api.post('member/updateProfile', body, {headers: {"Content-Type": "application/json"}})
    const retryBooking = (body) => api.post('payment', body);
    const airblueBooking = body => api.post('airBook', body);
    /*Dummy*/
    const bookSeatPost = (body) => api.post('bookSeat', body);

    const bookFlight = (body) => api.post('bookFlight', body)

    const createBooking = (body) => api.post('create_booking', body, {headers: {"Content-Type": "application/json"}});

    const bookingDetails = (body) => api.post('booking_details', body);

    const paymentDetails = (body) => api.post('payment_details', body);

    const fetchBookingDetails = (body) => api.post('fetch_booking', body)

    const confirmBooking = (body) => api.post('conf_booking', body)

    const findTicket = (body) => api.post(`findTicket`, body);

    return {
        booking,
        loginApi,
        bookSeat,
        getCities,
        bookFlight,
        findTicket,
        getAirport,
        registerApi,
        bookSeatPost,
        retryBooking,
        updateProfile,
        failedBooking,
        getViewTicket,
        createBooking,
        confirmBooking,
        airblueBooking,
        bookingDetails,
        paymentDetails,
        successBooking,
        getSingleFlight,
        fetchBookingDetails,
    }
}

const apis = createBackendServer(constants.ENDPOINT);

export default apis
