import React, {Fragment} from 'react'
import PhoneInput from "react-phone-input-2";

const HBLPayment = ({onChangeHandler, doHBLPaymentHandler, paymentData, isLoading}) => {
    return (
        <Fragment>
            <form onSubmit={doHBLPaymentHandler}>
                <div className="row">
                    <div className="form-group col-md-6">
                        <label htmlFor="name">Name</label>
                        <input id="name" className="form-control" type="text"
                               onChange={onChangeHandler} value={paymentData.name}
                               required/>
                    </div>

                    <div className="form-group col-md-6">
                        <label htmlFor="surname">Surname</label>
                        <input id="surname" className="form-control" type="text"
                               onChange={onChangeHandler} value={paymentData.surname}
                               required/>
                    </div>

                    <div className="form-group col-md-6">
                        <label htmlFor="email">Email</label>
                        <input id="email" className="form-control" type="email"
                               onChange={onChangeHandler} value={paymentData.email}
                               required/>
                    </div>

                    <div className="form-group col-md-6">
                        <label htmlFor="phone">Phone</label>
                        <PhoneInput
                            id="phone"
                            country={'pk'}
                            value={paymentData.phone}
                            onChange={(phone) => onChangeHandler({target: {id: 'phone', value: phone}})}
                        />
                    </div>

                    <div className="form-group col-md-4">
                        <label htmlFor="country">Country</label>
                        <input id="country" className="form-control" type="text"
                               onChange={onChangeHandler} value={paymentData.country}
                               required/>
                    </div>

                    <div className="form-group col-md-4">
                        <label htmlFor="postalCode">Postal Code</label>
                        <input id="postalCode" className="form-control"
                               type="number" onChange={onChangeHandler}
                               value={paymentData.postalCode} required/>
                    </div>

                    <div className="form-group col-md-4">
                        <label htmlFor="state">State</label>
                        <input id="state" className="form-control" type="text"
                               placeholder="State" onChange={onChangeHandler}
                               value={paymentData.state}
                               required/>
                    </div>

                    <div className="form-group col-md-12">
                        <label htmlFor="addressLine">Address</label>
                        <input id="addressLine" className="form-control"
                               type="text" onChange={onChangeHandler}
                               value={paymentData.addressLine} required/>
                    </div>

                </div>
                <div className="payment-btn">
                    <button type="submit"
                            disabled={isLoading}
                            className="btn btn-solid color1">
                        {isLoading ? 'Loading' : 'make payment'}
                    </button>
                </div>
            </form>
        </Fragment>
    )
};

export default HBLPayment;