import {
    ADD_SELECTED_FLIGHT,
    CLEAR_SELECTED_FLIGHT,
    CLEAR_SELECTED_FLIGHTS,
    RESET_FLIGHT_DATA,
    SAVE_BOOKING_ID,
    SAVE_PNR,
    SET_TRAVELERS
} from "redux/actionTypes";

export const setTravelers = (travelers) => dispatch => dispatch({type: SET_TRAVELERS, payload: travelers});

export const savePNR = (pnr) => dispatch => dispatch({type: SAVE_PNR, payload: pnr})

export const saveBookingId = (id) => dispatch =>
    dispatch({type: SAVE_BOOKING_ID, payload: id})

export const addFlight = (key, flight) => dispatch => {
    dispatch({type: ADD_SELECTED_FLIGHT, payload: {[key]: flight}})
}

export const clearFlight = (key) => dispatch => {
    dispatch({type: CLEAR_SELECTED_FLIGHT, payload: key})
}

export const clearSelectedFlights = () => dispatch => dispatch({type: CLEAR_SELECTED_FLIGHTS});


export const resetFlights = () => dispatch => dispatch({type: RESET_FLIGHT_DATA})