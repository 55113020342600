import {useMutation, useQuery} from "react-query";
import {useDispatch, useSelector} from "react-redux";
import {saveBookingId, savePNR} from "redux/actions/flight";
import apis from "services";

const useBookSeat = (params) => {
    const dispatch = useDispatch();

    const flight = useSelector(store => store.flight)
    console.log('useBookSeat.js: useBookSeat', flight)
    const {travelers} = useSelector(store => store.flight)


    const {flights, searchParams, summary, fareSummary} = params;
    const {ADULT, CHILD, INFANT} = travelers;
    const {return: returning, locationDep, locationArr, flightType} = searchParams;

    const totalSeats = ~~ADULT + ~~CHILD + ~~INFANT;
    const isRoundTrip = JSON.parse(returning);
    const flight_type = isRoundTrip ? 'RoundTrip' : 'OneWay';
    const itinerary = `${locationDep} - ${locationArr} ${isRoundTrip ? ` - ${locationDep}` : ''}`;
    const total_amount = Object.values(summary).reduce((acc, {total}) => acc + total, 0)

    if (flightType === 'one-way') {
        const flight = Object.values(flights)[0]
        var {AIRLINE, JOURNEY_CODE, BAGGAGE_FARE: {sub_class_id: FARE_TYPE}, CLASS_CODE, FLIGHT_NO} = flight;

    } else if (flightType === 'round') {
        const inbound = flights['inbound'];
        const outbound = flights['outbound'];
        var {AIRLINE, JOURNEY_CODE, BAGGAGE_FARE: {sub_class_id: FARE_TYPE}, CLASS_CODE, FLIGHT_NO} = outbound;
        var {
            JOURNEY_CODE: RETURN_JOURNEY_CODE,
            CLASS_CODE: RETURN_CLASS_CODE,
            FLIGHT_NO: RETURN_FLIGHT_NO,
            BAGGAGE_FARE: {sub_class_id: RETURN_FARE_TYPE}
        } = inbound;
    } else if (flightType === 'multi-city') {
    }

    /*const {mutate} = useMutation('bookSeat', apis.bookSeatPost, {
        onError: (error) => console.log('Error Occurred whiling Booking seat', error),
        onSuccess: ({data}) => {
            console.log('PNR FROM POST->', data);
            dispatch(savePNR(data))
        }
    });*/

    const bookSeatParams = {
        method: 'bookSeat',
        Return: returning,
        DepartureJourney: JOURNEY_CODE,
        DepartureFareType: FARE_TYPE,
        DepartureClass: CLASS_CODE,
        DepartureFlight: FLIGHT_NO,
        ReturningJourney: RETURN_JOURNEY_CODE,
        ReturningClass: RETURN_CLASS_CODE,
        ReturningFlight: RETURN_FLIGHT_NO,
        ReturningFareType: RETURN_FARE_TYPE,
        LocationDep: locationDep,
        LocationArr: locationArr,
        ftype: 'dom',
        TotalSeats: totalSeats,
        totalInfant: INFANT,
        totalAdult: ADULT,
        totalChild: CHILD
    };

    const bookingParams = {
        airline: AIRLINE,
        pnr: 'Null',
        total_amount,
        tax: 0,
        summary: fareSummary,
        amount: total_amount,
        discount: 0,
        itinerary,
        flight_type,
        travelers: travelers,
        flight_summary: flights,
    }


    useQuery(Object.values(bookSeatParams), apis.bookSeat, {
        retry: false,
        onError: (error) => {
            // console.log('Error Occurred whiling creating pnr', error);
            createBooking(bookingParams);
        },
        onSuccess: ({data: pnr}) => {
            const isValidPNR = typeof pnr === 'string';

            if (isValidPNR) {
                dispatch(savePNR(pnr))
            }

            createBooking({...bookingParams, pnr: isValidPNR ? pnr : 'no'});
        }
    });

    const {mutate: createBooking} = useMutation('create_booking', apis.createBooking, {
        onError: (error) => console.log('Error Occurred while Booking Seat', error),
        onSuccess: ({data}) => dispatch(saveBookingId(data))
    })
}

export default useBookSeat
