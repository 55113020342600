import React from "react";
import generateRandomString from "utils/generateRandomString";

const Accordion = ({title, children, expanded, icon = 'radio'}) => {
    const id = generateRandomString();
    const uniqId = generateRandomString();
    return (<div className="card">
        <div className="card-header" id={id}>
            <div className="btn btn-link" data-bs-toggle="collapse"
                 data-bs-target={`#${uniqId}`} aria-expanded="true" aria-controls="one">
                <label htmlFor={id}>
                    {icon === 'radio'
                        ? <input type='radio' className="radio_animated ms-0" defaultChecked={expanded}
                                 id={id}
                                 name='occupation' value='Working' required/>
                        : <i className={`fas fa-${icon} me-2`}/>
                    }
                    {title}
                </label>
            </div>
        </div>
        <div id={uniqId} className={`collapse ${expanded && 'show'}`} aria-labelledby={id}
             data-bs-parent="#accordionExample">
            <div className="card-body">
                {children}
            </div>
        </div>
    </div>)
}


export default Accordion
